import React from 'react'
import { Fragment } from 'react'
import Header from './Component/Header';
import Categories from './Component/Categories';
import Categoriesmenu from './Component/Categories menu';
import Footer from './Component/Footer';
import Topbar from './Component/Topbar';
import Find from './Component/Find';
import Lproperties from './Component/Lproperties';
import Fproperties from './Component/Fproperties';
import Statistics from './Component/Statistics';
import Bproperties from './Component/Bproperties';
import Toplisting from './Component/Top-listing';
// import Download from './Component/Download';
import RecentPost from './Component/Recent Post';
// import HousingCategories from './HousingCategories';

const home = () => {
	return (
		<Fragment>

			{/* <--Topbar --> */}
			<Topbar/>
			{/* <--Topbar --> */}

			{/* <-- Header section --> */}
			<Header />
			{/* <-- Header section --> */}


			{/* <!--Find Your Best Property Sliders Section--> */}
			<Find/>
			{/* <!--Find Your Best Property Sliders Section--> */}

			{/* <!--Sliders Section--> */}

			{/* <!--Categories  Menu --> */}
			<Categoriesmenu />
			{/* <!--Categories Menu-->

		<!--Categories--> */}
			<Categories />
			{/* <HousingCategories/> */}
			{/* <!--/Categories-->

		<!--LatestProperties Ads--> */}
			<Lproperties/>
		{/* <!--Latest Properties Ads-->

		<!--Featured Properties Ads--> */}
			<Fproperties/>
			{/* <!--/Featured Properties Ads-->

		<!--Statistics--> */}
			<Statistics/>
			{/* <!--/Statistics-->

		<!--Property--> */}
			<Bproperties/>
			{/* <!--/Property-->

		<!--Testimonials--> */}
			{/* <Testimonials/> */}
			{/* <!--/Testimonials--> */}

		{/* <!--Top Lisiting Places--> */}
			<Toplisting/>
			{/* <!--Top Lisiting Places--> */}

		{/* <!--Download --> */}
			{/* <Download/> */}
			{/* <!--Download -->

		<!-- Recent Posts --> */}
			<RecentPost/>
			{/* <!--Recent Posts -->

		<!--Footer Section--> */}
			<Footer />
			 {/* <!--Footer Section--> */}

	
			
		</Fragment>

	)
}

export default home