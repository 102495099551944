import React, { Fragment } from 'react'

const Toplisting = () => {
    return (
        <Fragment>
            {/* <!--Top Lisiting Places--> */}
            <section className="sptb bg-white">
                <div className="container">
                    <div className="section-title center-block text-center">
                        <h2>Top Lisiting Places</h2>
                        <p>The size of the property and its layout play a significant role in determining its suitability for a buyer's needs.</p>
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-12 col-lg-12 col-xl-6">
                            <div className="row">
                                <div className="col-sm-12 col-lg-6 col-md-6 ">
                                    <div className="item-card overflow-hidden">
                                        <div className="item-card-desc">
                                            <div className="card text-center overflow-hidden">
                                                <div className="card-img">
                                                    <img src="assets/images/locations/germany.jpg" alt="img" className="cover-image" />
                                                </div>
                                                <div className="item-tags">
                                                    <div className="bg-primary tag-option"><i className="fa fa fa-heart-o me-1"></i> 786</div>
                                                </div>
                                                <div className="item-card-text">
                                                    <h4 className="mb-0">44,327<span><i className="fa fa-map-marker me-1 text-primary"></i>GERMANY</span></h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-12 col-lg-6 col-md-6 ">
                                    <div className="item-card overflow-hidden">
                                        <div className="item-card-desc">
                                            <div className="card text-center overflow-hidden">
                                                <div className="card-img">
                                                    <img src="assets/images/locations/london.jpg" alt="img" className="cover-image" />
                                                </div>
                                                <div className="item-tags">
                                                    <div className="bg-secondary tag-option"><i className="fa fa fa-heart-o me-1"></i> 89</div>
                                                </div>
                                                <div className="item-card-text">
                                                    <h4 className="mb-0">52,145<span><i className="fa fa-map-marker me-1 text-primary"></i> LONDON</span></h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-12 col-lg-6 col-md-6 ">
                                    <div className="item-card overflow-hidden">
                                        <div className="item-card-desc">
                                            <div className="card text-center overflow-hidden mb-lg-0">
                                                <div className="card-img">
                                                    <img src="assets/images/locations/austerlia.jpg" alt="img" className="cover-image" />
                                                </div>
                                                <div className="item-tags">
                                                    <div className="bg-primary tag-option"><i className="fa fa fa-heart-o me-1"></i> 894</div>
                                                </div>
                                                <div className="item-card-text">
                                                    <h4 className="mb-0">63,263<span><i className="fa fa-map-marker text-primary me-1"></i>AUSTERLIA</span></h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-12 col-lg-6 col-md-6 ">
                                    <div className="item-card overflow-hidden">
                                        <div className="item-card-desc">
                                            <div className="card text-center overflow-hidden mb-lg-0">
                                                <div className="card-img">
                                                    <img src="assets/images/locations/chicago.jpg" alt="img" className="cover-image" />
                                                </div>
                                                <div className="item-tags">
                                                    <div className="bg-secondary tag-option"><i className="fa fa fa-heart-o me-1"></i> 123 </div>
                                                </div>
                                                <div className="item-card-text">
                                                    <h4 className="mb-0">36,485<span><i className="fa fa-map-marker text-primary me-1"></i>CHICAGO</span></h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12 col-md-12 col-xl-6 col-sm-12">
                            <div className="item-card overflow-hidden">
                                <div className="item-card-desc">
                                    <div className="card overflow-hidden mb-0">
                                        <div className="card-img">
                                            <img src="assets/images/locations/losangels-1.jpg" alt="img" className="cover-image" />
                                        </div>
                                        <div className="item-tags">
                                            <div className="bg-primary tag-option"><i className="fa fa fa-heart-o me-1"></i> 567 </div>
                                        </div>
                                        <div className="item-card-text">
                                            <h4 className="mb-0">64,825<span><i className="fa fa-map-marker text-primary me-1"></i>WASHINGTON</span></h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!--Top Lisiting Places--> */}
        </Fragment>
    )
}

export default Toplisting