import React, { Fragment } from 'react'
import HeaderProperties from './Component/HeaderProperties'
import AvailableProperties from './Component/AvailableProperties'
import Footer from './Component/Footer'
import { Link } from 'react-router-dom'

function $2BHKHouse() {
  return (
    <Fragment>
        <HeaderProperties/>

        <AvailableProperties/>



        {/* <!--Add listing--> */}
		<section className="sptb">
			<div className="container">
				<div className="row">
					<div className="col-xl-8 col-lg-8 col-md-12">

						{/* <!--Classified Description--> */}
						<div className="card overflow-hidden">
							<div className="ribbon ribbon-top-right text-danger"><span className="bg-danger">Offer</span></div>
							<div className="card-body">
								<div className="item-det mb-4">
									<Link to="javascript:void(0);" className="text-dark"><h3 className="">Affordable 2 BHK Flats Available in Gated Community</h3></Link>
									<ul className="d-flex">
										<li className="me-5"><Link to="javascript:void(0);" className="icons"><i className="icon icon-briefcase text-muted me-1"></i> RealEstate</Link></li>
										<li className="me-5"><Link to="javascript:void(0);" className="icons"><i className="icon icon-location-pin text-muted me-1"></i> USA</Link></li>
										<li className="me-5"><Link to="javascript:void(0);" className="icons"><i className="icon icon-calendar text-muted me-1"></i> 5 hours ago</Link></li>
										<li className="me-5"><Link to="javascript:void(0);" className="icons"><i className="icon icon-eye text-muted me-1"></i> 765</Link></li>
										<li className=""><Link to="javascript:void(0);" className="icons">
										<i className="fa fa-star text-warning"></i>
										<i className="fa fa-star text-warning"></i>
										<i className="fa fa-star text-warning"></i>
										<i className="fa fa-star text-warning"></i>
										<i className="fa fa-star-half-o text-warning me-1"></i>4.5</Link></li>
									</ul>
								</div>
								<div className="product-slider carousel-slide-1">
									<div id="carouselFade" className="carousel slide carousel-fade" data-bs-ride="carousel"
										data-bs-loop="false" data-bs-thumb="true" data-bs-dots="false">
										<div className="arrow-ribbon2 bg-primary">$539</div>
										<div className="carousel-inner slide-show-image" id="full-gallery">
											<div className="carousel-item active"><img src="assets/images/products/products/h1.jpg" alt="img"/> </div>
											<div className="carousel-item"><img src="assets/images/products/products/b4.jpg" alt="img"/> </div>
											<div className="carousel-item"><img src="assets/images/products/products/h3.jpg" alt="img"/> </div>
											<div className="carousel-item"><img src="assets/images/products/products/h4.jpg" alt="img"/> </div>
											<div className="carousel-item"><img src="assets/images/products/products/h5.jpg" alt="img"/> </div>
											<div className="carousel-item"><img src="assets/images/products/products/h6.jpg" alt="img"/> </div>
											<div className="carousel-item"><img src="assets/images/products/products/b1.jpg" alt="img"/> </div>
											<div className="carousel-item"><img src="assets/images/products/products/b2.jpg" alt="img"/> </div>
											<div className="carousel-item"><img src="assets/images/products/products/b3.jpg" alt="img"/> </div>
											<div className="carousel-item"><img src="assets/images/products/products/b4.jpg" alt="img"/> </div>
											<div className="thumbcarousel">
												<Link className="carousel-control-prev" to="#carouselFade" role="button"
													data-bs-slide="prev">
													<i className="fa fa-angle-left" aria-hidden="true"></i>
												</Link>
												<Link className="carousel-control-next" to="#carouselFade" role="button"
													data-bs-slide="next">
													<i className="fa fa-angle-right" aria-hidden="true"></i>
												</Link>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="card">
							<div className="card-header">
								<h3 className="card-title">Description</h3>
							</div>
							<div className="card-body">
								<div className="mb-4">
									<p>Luxury Home  For Sale odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atcorrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga.</p>
									<p>On the other hand, we denounce with righteous indignation and dislike men who are so beguiled and demoraliz the charms of pleasure of the moment, so blinded by desire, that they cannot foresee the pain and trouble thena bound to ensue; and equal blame belongs to those who fail in their duty through weakness of will, which is the same as saying through shrinking from toil and pain.</p>
								</div>
								<h4 className="mb-4">Specifications</h4>
								<div className="row">
									<div className="col-xl-6 col-md-12">
										<ul className="list-unstyled widget-spec mb-0">
											<li className="">
												<i className="fa fa-bed text-muted w-5"></i> 2 BedRooms
											</li>
											<li className="">
												<i className="fa fa-bath text-muted w-5"></i> 2 BathRooms
											</li>
											<li className="">
												<i className="fa fa-life-ring text-muted w-5"></i> Unfurnished
											</li>
											<li className="">
												<i className="fa fa-car text-muted w-5" ></i> 2 Car Parking
											</li>
											<li className="">
												<i className="fa fa-globe text-muted w-5"></i> East East face
											</li>
											<li className="mb-xl-0">
												<i className="fa fa-pagelines text-muted w-5"></i> Garden
											</li>
										</ul>
									</div>
									<div className="col-xl-6 col-md-12">
										<ul className="list-unstyled widget-spec mb-0">
											<li className="">
												<i className="fa fa-lock text-muted w-5"></i> Security
											</li>
											<li className="">
												<i className="fa fa-building-o text-muted w-5"></i> Lift
											</li>
											<li className="">
												<i className="fa fa-check text-muted w-5"></i> Swimming fool
											</li>
											<li className="">
												<i className="fa fa-gamepad text-muted w-5"></i> Play Area
											</li>
											<li className="">
												<i className="fa fa-futbol-o text-muted w-5"></i> football Court
											</li>
											<li className="mb-0">
												<i className="fa fa-trophy text-muted w-5"></i> Cricket Court
											</li>
										</ul>
									</div>
								</div>
							</div>
							<div className="pt-4 pb-4 ps-5 pe-5 border-top border-top">
								<div className="list-id">
									<div className="row">
										<div className="col">
											<Link className="mb-0">Classified ID : #8256358</Link>
										</div>
										<div className="col col-auto">
											Posted By <Link className="mb-0 font-weight-bold">Individual</Link> / 21st Dec 2019
										</div>
									</div>
								</div>
							</div>
							<div className="card-footer">
								<div className="icons">
									<Link to="javascript:void(0);" className="btn btn-info icons"><i className="icon icon-share me-1"></i> Share Ad</Link>
									<Link to="javascript:void(0);" className="btn btn-primary icons"><i className="icon icon-heart  me-1"></i> 678</Link>
									<Link to="javascript:void(0);" className="btn btn-secondary icons"><i className="icon icon-printer  me-1"></i> Print</Link>
								</div>
							</div>
						</div>
						{/* <!--/Classified Description--> */}

						<h3 className="mb-5 mt-4">Related Posts</h3>

						{/* <!--Related Posts--> */}
						<div id="myCarousel5" className="owl-carousel owl-carousel-icons3">
							{/* <!-- Wrapper for carousel items --> */}

							<div className="item">
								<div className="card">
									<div className="arrow-ribbon bg-primary">For Sale</div>
									<div className="item-card7-imgs">
										<Link to="col-left.html"></Link>
										<img src="assets/images/products/products/ed1.jpg" alt="img" className="cover-image"/>
									</div>
									<div className="item-card7-overlaytext">
										<Link to="col-left.html" className="text-white">Duplex House</Link>
										<h4 className="mb-0">$389</h4>
									</div>
									<div className="card-body">
										<div className="item-card7-desc">
											<Link to="col-left.html" className="text-dark"><h4 className="font-weight-semibold">House For Sale</h4></Link>
										</div>
										<div className="item-card7-text">
											<ul className="icon-card mb-0">
												<li ><Link to="javascript:void(0);" className="icons"><i className="icon icon-location-pin text-muted me-1"></i>  Los Angles</Link></li>
												<li><Link to="javascript:void(0);" className="icons"><i className="icon icon-event text-muted me-1"></i> 5 hours ago</Link></li>
												<li className="mb-0"><Link to="javascript:void(0);" className="icons"><i className="icon icon-user text-muted me-1"></i> Sally Peake</Link></li>
												<li className="mb-0"><Link to="javascript:void(0);" className="icons"><i className="icon icon-phone text-muted me-1"></i> 5-67987608</Link></li>
											</ul>
											<p className="mb-0"> Built Up 1250 Sq.ft</p>
										</div>
									</div>
								</div>
							</div>
							<div className="item">
								<div className="card">
									<div className="arrow-ribbon bg-secondary">For Rent</div>
									<div className="item-card7-imgs">
										<Link to="col-left.html"></Link>
										<img src="assets/images/products/products/v1.jpg" alt="img" className="cover-image"/>
									</div>
									<div className="item-card7-overlaytext">
										<Link to="col-left.html" className="text-white">Luxury Room</Link>
										<h4 className=" mb-0">$854</h4>
									</div>
									<div className="card-body">
										<div className="item-card7-desc">
											<Link to="col-left.html" className="text-dark"><h4 className="font-weight-semibold">Luxury Flat For Sale</h4></Link>
										</div>
										<div className="item-card7-text">
											<ul className="icon-card mb-0">
												<li ><Link to="javascript:void(0);" className="icons"><i className="icon icon-location-pin text-muted me-1"></i>  Los Angles</Link></li>
												<li><Link to="javascript:void(0);" className="icons"><i className="icon icon-event text-muted me-1"></i> 5 hours ago</Link></li>
												<li className="mb-0"><Link to="javascript:void(0);" className="icons"><i className="icon icon-user text-muted me-1"></i> Sally Peake</Link></li>
												<li className="mb-0"><Link to="javascript:void(0);" className="icons"><i className="icon icon-phone text-muted me-1"></i> 5-67987608</Link></li>
											</ul>
											<p className="mb-0">Built Up 650 Sq.ft</p>
										</div>
									</div>
								</div>
							</div>
							<div className="item">
								<div className="card">
									<div className="item-card7-imgs">
										<Link to="col-left.html"></Link>
										<img src="assets/images/products/products/j1.jpg" alt="img" className="cover-image"/>
									</div>
									<div className="item-card7-overlaytext">
										<Link to="col-left.html" className="text-white">3BHK House</Link>
										<h4 className=" mb-0">$786</h4>
									</div>
									<div className="card-body">
										<div className="item-card7-desc">
											<Link to="col-left.html" className="text-dark"><h4 className="font-weight-semibold">Home For Sale</h4></Link>
										</div>
										<div className="item-card7-text">
											<ul className="icon-card mb-0">
												<li ><Link to="javascript:void(0);" className="icons"><i className="icon icon-location-pin text-muted me-1"></i>  Los Angles</Link></li>
												<li><Link to="javascript:void(0);" className="icons"><i className="icon icon-event text-muted me-1"></i> 5 hours ago</Link></li>
												<li className="mb-0"><Link to="javascript:void(0);" className="icons"><i className="icon icon-user text-muted me-1"></i> Sally Peake</Link></li>
												<li className="mb-0"><Link to="javascript:void(0);" className="icons"><i className="icon icon-phone text-muted me-1"></i> 5-67987608</Link></li>
											</ul>
											<p className="mb-0">Built Up 1100 Sq.ft</p>
										</div>
									</div>
								</div>
							</div>
							<div className="item">
								<div className="card">
									<div className="arrow-ribbon bg-primary">For Sale</div>
									<div className="item-card7-imgs">
										<Link to="col-left.html"></Link>
										<img src="assets/images/products/products/f4.jpg" alt="img" className="cover-image"/>
									</div>
									<div className="item-card7-overlaytext">
										<Link to="col-left.html" className="text-white">2BHK House</Link>
										<h4 className="mb-0">$539</h4>
									</div>
									<div className="card-body">
										<div className="item-card7-desc">
											<Link to="rcol-left.html" className="text-dark"><h4 className="font-weight-semibold">2BHK Flat For Rent</h4></Link>
										</div>
										<div className="item-card7-text">
											<ul className="icon-card mb-0">
												<li ><Link to="javascript:void(0);" className="icons"><i className="icon icon-location-pin text-muted me-1"></i>  Los Angles</Link></li>
												<li><Link to="javascript:void(0);" className="icons"><i className="icon icon-event text-muted me-1"></i> 5 hours ago</Link></li>
												<li className="mb-0"><Link to="javascript:void(0);" className="icons"><i className="icon icon-user text-muted me-1"></i> Sally Peake</Link></li>
												<li className="mb-0"><Link to="javascript:void(0);" className="icons"><i className="icon icon-phone text-muted me-1"></i> 5-67987608</Link></li>
											</ul>
											<p className="mb-0">Built Up 500 Sq.ft</p>
										</div>
									</div>
								</div>
							</div>
							<div className="item">
								<div className="card">
									<div className="item-card7-imgs">
										<Link to="col-left.html"></Link>
										<img src="assets/images/products/products/pel.jpg" alt="img" className="cover-image"/>
									</div>
									<div className="item-card7-overlaytext">
										<Link to="col-left.html" className="text-white"> 2BHk House</Link>
										<h4 className=" mb-0">$925</h4>
									</div>
									<div className="card-body">
										<div className="item-card7-desc">
											<Link to="col-left.html" className="text-dark"><h4 className="font-weight-semibold">Flat For Rent</h4></Link>
										</div>
										<div className="item-card7-text">
											<ul className="icon-card mb-0">
												<li ><Link to="javascript:void(0);" className="icons"><i className="icon icon-location-pin text-muted me-1"></i>  Los Angles</Link></li>
												<li><Link to="javascript:void(0);" className="icons"><i className="icon icon-event text-muted me-1"></i> 5 hours ago</Link></li>
												<li className="mb-0"><Link to="javascript:void(0);" className="icons"><i className="icon icon-user text-muted me-1"></i> Sally Peake</Link></li>
												<li className="mb-0"><Link to="javascript:void(0);" className="icons"><i className="icon icon-phone text-muted me-1"></i> 5-67987608</Link></li>
											</ul>
											<p className="mb-0">Built Up 1000 Sq.ft</p>
										</div>
									</div>
								</div>
							</div>
							<div className="item">
								<div className="card">
									<div className="arrow-ribbon bg-secondary">For Rent</div>
									<div className="item-card7-imgs">
										<Link to="col-left.html"></Link>
										<img src="assets/images/products/products/h5.jpg" alt="img" className="cover-image"/>
									</div>
									<div className="item-card7-overlaytext">
										<Link to="col-left.html" className="text-white"> Duplex House</Link>
										<h4 className="mb-0">$925</h4>
									</div>
									<div className="card-body">
										<div className="item-card7-desc">
											<Link to="col-left.html" className="text-dark"><h4 className="font-weight-semibold"> Single Flat House For Rent</h4></Link>
										</div>
										<div className="item-card7-text">
											<ul className="icon-card mb-0">
												<li ><Link to="javascript:void(0);" className="icons"><i className="icon icon-location-pin text-muted me-1"></i>  Los Angles</Link></li>
												<li><Link to="javascript:void(0);" className="icons"><i className="icon icon-event text-muted me-1"></i> 5 hours ago</Link></li>
												<li className="mb-0"><Link to="javascript:void(0);" className="icons"><i className="icon icon-user text-muted me-1"></i> Sally Peake</Link></li>
												<li className="mb-0"><Link to="javascript:void(0);" className="icons"><i className="icon icon-phone text-muted me-1"></i> 5-67987608</Link></li>
											</ul>
											<p className="mb-0">Built Up 3450 Sq.ft</p>
										</div>
									</div>
								</div>
							</div>
							<div className="item">
								<div className="card">
									<div className="item-card7-imgs">
										<Link to="col-left.html"></Link>
										<img src="assets/images/products/products/ed2.jpg" alt="img" className="cover-image"/>
									</div>
									<div className="item-card7-overlaytext">
										<Link to="col-left.html" className="text-white">Luxury Room</Link>
										<h4 className=" mb-0">$378</h4>
									</div>
									<div className="card-body">
										<div className="item-card7-desc">
											<Link to="col-left.html" className="text-dark"><h4 className="font-weight-semibold">House For Sale</h4></Link>
										</div>
										<div className="item-card7-text">
											<ul className="icon-card mb-0">
												<li ><Link to="javascript:void(0);" className="icons"><i className="icon icon-location-pin text-muted me-1"></i>  Los Angles</Link></li>
												<li><Link to="javascript:void(0);" className="icons"><i className="icon icon-event text-muted me-1"></i> 5 hours ago</Link></li>
												<li className="mb-0"><Link to="javascript:void(0);" className="icons"><i className="icon icon-user text-muted me-1"></i> Sally Peake</Link></li>
												<li className="mb-0"><Link to="javascript:void(0);" className="icons"><i className="icon icon-phone text-muted me-1"></i> 5-67987608</Link></li>
											</ul>
											<p className="mb-0">Built Up 900 Sq.ft</p>
										</div>
									</div>
								</div>
							</div>
							<div className="item">
								<div className="card">
									<div className="arrow-ribbon bg-primary">For Sale</div>
									<div className="item-card7-imgs">
										<Link to="col-left.html"></Link>
										<img src="assets/images/products/products/j3.jpg" alt="img" className="cover-image"/>
									</div>
									<div className="item-card7-overlaytext">
										<Link to="col-left.html" className="text-white">Budget House</Link>
										<h4 className=" mb-0">$836</h4>
									</div>
									<div className="card-body">
										<div className="item-card7-desc">
											<Link to="col-left.html" className="text-dark"><h4 className="font-weight-semibold">2BHK House For Rent</h4></Link>
										</div>
										<div className="item-card7-text">
											<ul className="icon-card mb-0">
												<li ><Link to="javascript:void(0);" className="icons"><i className="icon icon-location-pin text-muted me-1"></i>  Los Angles</Link></li>
												<li><Link to="javascript:void(0);" className="icons"><i className="icon icon-event text-muted me-1"></i> 5 hours ago</Link></li>
												<li className="mb-0"><Link to="javascript:void(0);" className="icons"><i className="icon icon-user text-muted me-1"></i> Sally Peake</Link></li>
												<li className="mb-0"><Link to="javascript:void(0);" className="icons"><i className="icon icon-phone text-muted me-1"></i> 5-67987608</Link></li>
											</ul>
											<p className="mb-0">Built Up 2000 Sq.ft</p>
										</div>
									</div>
								</div>
							</div>
						</div>
						{/* <!--/Related Posts--> */}

						{/* <!--Comments--> */}
						<div className="card">
							<div className="card-header">
								<h3 className="card-title">Rating And Reviews</h3>
							</div>
							<div className="card-body">
								<div className="row">
									<div className="col-md-12">
										<div className="mb-4">
											<p className="mb-2">
												<span className="fs-14 ms-2"><i className="fa fa-star text-yellow me-2"></i>5</span>
											</p>
											<div className="progress progress-md mb-4 h-4">
												<div className="progress-bar bg-success w-100">9,232</div>
											</div>
										</div>
										<div className="mb-4">
											<p className="mb-2">
												<span className="fs-14 ms-2"><i className="fa fa-star text-yellow me-2"></i>4</span>
											</p>
											<div className="progress progress-md mb-4 h-4">
												<div className="progress-bar bg-info w-80">8,125</div>
											</div>
										</div>
										<div className="mb-4">
											<p className="mb-2">
												<span className="fs-14 ms-2"><i className="fa fa-star text-yellow me-2"></i>  3</span>
											</p>
											<div className="progress progress-md mb-4 h-4">
												<div className="progress-bar bg-primary w-60">6,263</div>
											</div>
										</div>
										<div className="mb-4">
											<p className="mb-2">
												<span className="fs-14 ms-2"><i className="fa fa-star text-yellow me-2"></i>  2</span>
											</p>
											<div className="progress progress-md mb-4 h-4">
												<div className="progress-bar bg-secondary w-30">3,463</div>
											</div>
										</div>
										<div className="mb-5">
											<p className="mb-2">
												<span className="fs-14 ms-2"><i className="fa fa-star text-yellow me-2"></i>  1</span>
											</p>
											<div className="progress progress-md mb-4 h-4">
												<div className="progress-bar bg-orange w-20">1,456</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="card-body p-0">
								<div className="media mt-0 p-5">
                                    <div className="d-flex me-3">
                                        <Link to="javascript:void(0);"><img className="media-object brround" alt="64x64" src="assets/images/faces/male/1.jpg"/> </Link>
                                    </div>
                                    <div className="media-body">
                                        <h5 className="mt-0 mb-1 font-weight-semibold">Joanne Scott
											<span className="fs-14 ms-0" data-bs-toggle="tooltip" data-bs-placement="top" title="" data-bs-original-title="verified"><i className="fa fa-check-circle-o text-success"></i></span>
											<span className="fs-14 ms-2"> 4.5 <i className="fa fa-star text-yellow"></i></span>
										</h5>
										<small className="text-muted"><i className="fa fa-calendar"></i> Dec 21st  <i className=" ms-3 fa fa-clock-o"></i> 13.00  <i className=" ms-3 fa fa-map-marker"></i> Brezil</small>
                                        <p className="font-13  mb-2 mt-2">
                                           Ut enim ad minim veniam, quis Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et  nostrud exercitation ullamco laboris   commodo consequat.
                                        </p>
										<Link to="javascript:void(0);" className="me-2 btn btn-primary btn-sm m-1"><span className="">Helpful</span></Link>
										<Link to="javascript:void(0);" className="me-2 btn btn-default btn-sm m-1" data-bs-toggle="modal" data-bs-target="#Comment"><span className="">Comment</span></Link>
										<Link to="javascript:void(0);" className="me-2 btn btn-default btn-sm m-1" data-bs-toggle="modal" data-bs-target="#report"><span className="">Report</span></Link>
                                        <div className="media mt-5">
                                            <div className="d-flex me-3">
                                                <Link to="javascript:void(0);"> <img className="media-object brround" alt="64x64" src="assets/images/faces/female/2.jpg"/> </Link>
                                            </div>
											<div className="media-body">
												<h5 className="mt-0 mb-1 font-weight-semibold">Rose Slater <span className="fs-14 ms-0" data-bs-toggle="tooltip" data-bs-placement="top" title="" data-bs-original-title="verified"><i className="fa fa-check-circle-o text-success"></i></span></h5>
												<small className="text-muted"><i className="fa fa-calendar"></i> Dec 22st  <i className=" ms-3 fa fa-clock-o"></i> 6.00  <i className=" ms-3 fa fa-map-marker"></i> Brezil</small>
												<p className="font-13  mb-2 mt-2">
												   Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris   commodo At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium, nisi ut aliquid ex ea commodi consequatur consequat.
												</p>
												<Link to="javascript:void(0);" className="btn btn-default btn-sm" data-bs-toggle="modal" data-bs-target="#Comment"><span className="">Comment</span></Link>
											</div>
										</div>
									</div>
								</div>
								<div className="media p-5 border-top mt-0">
									<div className="d-flex me-3">
										<Link to="javascript:void(0);"> <img className="media-object brround" alt="64x64" src="assets/images/faces/male/3.jpg"/> </Link>
									</div>
									<div className="media-body">
										<h5 className="mt-0 mb-1 font-weight-semibold">Edward
										<span className="fs-14 ms-0" data-bs-toggle="tooltip" data-bs-placement="top" title="" data-bs-original-title="verified"><i className="fa fa-check-circle-o text-success"></i></span>
										<span className="fs-14 ms-2"> 4 <i className="fa fa-star text-yellow"></i></span>
										</h5>
										<small className="text-muted"><i className="fa fa-calendar"></i> Dec 21st  <i className=" ms-3 fa fa-clock-o"></i> 16.35  <i className=" ms-3 fa fa-map-marker"></i> UK</small>
                                        <p className="font-13  mb-2 mt-2">
                                           Ut enim ad minim veniam, quis Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et  nostrud exercitation ullamco laboris   commodo consequat.
                                        </p>
										<Link to="javascript:void(0);" className="me-2 btn btn-primary btn-sm m-1"><span className="">Helpful</span></Link>
										<Link to="javascript:void(0);" className="me-2 btn btn-default btn-sm m-1" data-bs-toggle="modal" data-bs-target="#Comment"><span className="">Comment</span></Link>
										<Link to="javascript:void(0);" className="me-2 btn btn-default btn-sm m-1" data-bs-toggle="modal" data-bs-target="#report"><span className="">Report</span></Link>
									</div>
								</div>
							</div>
						</div>
						{/* <!--/Comments--> */}

						<div className="card mb-lg-0">
							<div className="card-header">
								<h3 className="card-title">Leave a reply</h3>
							</div>
							<div className="card-body">
								<div>
									<div className="form-group">
										<input type="text" className="form-control" id="name1" placeholder="Your Name"/>
									</div>
									<div className="form-group">
										<input type="email" className="form-control" id="email" placeholder="Email Address"/>
									</div>
									<div className="form-group">
										<textarea className="form-control" name="example-textarea-input" rows="6" placeholder="Comment"></textarea>
									</div>
									<Link to="javascript:void(0);" className="btn btn-primary">Send Reply</Link>
								</div>
							</div>
						</div>
					</div>

					{/* <!--Right Side Content--> */}
					<div className="col-xl-4 col-lg-4 col-md-12">
						<div className="card">
							<div className="card-header">
								<h3 className="card-title">Posted By</h3>
							</div>
							<div className="card-body  item-user">
								<div className="profile-pic mb-0">
									<img src="assets/images/faces/male/25.jpg" className="brround avatar-xxl" alt="user"/>
									<div className="">
										<Link to="userprofile2.html" className="text-dark"><h4 className="mt-3 mb-1 font-weight-semibold">Robert McLean</h4></Link>
										<p className="mb-0">RealEstate Agent</p>
										<span className="text-muted">Member Since November 2008</span>
										<h6 className="mt-2 mb-0"><Link to="userprofile2.html" className="btn btn-primary btn-sm">See All Ads</Link></h6>
									</div>
								</div>
							</div>
							<div className="card-body item-user">
								<h4 className="mb-4">Contact Info</h4>
								<div>
									<h6><span className="font-weight-semibold"><i className="fa fa-map-marker me-2 mb-2"></i></span><Link to="javascript:void(0);" className="text-body"> 7981 Aspen Ave. Hammonton,  USA</Link></h6>
									<h6><span className="font-weight-semibold"><i className="fa fa-envelope me-2 mb-2"></i></span><Link to="javascript:void(0);" className="text-body"> robert123@gmail.com</Link></h6>
									<h6><span className="font-weight-semibold"><i className="fa fa-phone me-2  mb-2"></i></span><Link to="javascript:void(0);" className="text-body"> 0-235-657-24587</Link></h6>
									<h6><span className="font-weight-semibold"><i className="fa fa-link me-2 "></i></span><Link to="javascript:void(0);" className="text-body">http://spruko.com/</Link></h6>
								</div>
								<div className=" item-user-icons mt-4">
									<Link to="javascript:void(0);" className="facebook-bg mt-0"><i className="fa fa-facebook"></i></Link>
									<Link to="javascript:void(0);" className="twitter-bg"><i className="fa fa-twitter"></i></Link>
									<Link to="javascript:void(0);" className="google-bg"><i className="fa fa-google"></i></Link>
									<Link to="javascript:void(0);" className="dribbble-bg"><i className="fa fa-dribbble"></i></Link>
								</div>
							</div>
							<div className="card-footer">
								<div className="text-start">
									<Link to="javascript:void(0);" className="btn  btn-success m-1"><i className="fa fa-envelope"></i> Chat</Link>
									<Link to="javascript:void(0);" className="btn btn-primary m-1" data-bs-toggle="modal" data-bs-target="#contact"><i className="fa fa-user"></i> Contact Me</Link>
									<Link to="javascript:void(0);" className="btn  btn-info m-1"><i className="fa fa-share"></i> Share</Link>
								</div>
							</div>
						</div>
						<div className="card">
							<div className="card-header">
								<h3 className="card-title">Keywords</h3>
							</div>
							<div className="card-body product-filter-desc">
								<div className="product-tags clearfix">
									<ul className="list-unstyled mb-0">
										<li><Link to="javascript:void(0);">Home</Link></li>
										<li><Link to="javascript:void(0);">Real estate</Link></li>
										<li><Link to="javascript:void(0);">2BHK</Link></li>
									</ul>
								</div>
							</div>
						</div>
						<div className="card">
							<div className="card-header">
								<h3 className="card-title">Shares</h3>
							</div>
							<div className="card-body product-filter-desc">
								<div className="product-filter-icons text-center">
									<Link to="javascript:void(0);" className="facebook-bg"><i className="fa fa-facebook"></i></Link>
									<Link to="javascript:void(0);" className="twitter-bg"><i className="fa fa-twitter"></i></Link>
									<Link to="javascript:void(0);" className="google-bg"><i className="fa fa-google"></i></Link>
									<Link to="javascript:void(0);" className="dribbble-bg"><i className="fa fa-dribbble"></i></Link>
									<Link to="javascript:void(0);" className="pinterest-bg"><i className="fa fa-pinterest"></i></Link>
								</div>
							</div>
						</div>
						<div className="card">
							<div className="card-header">
								<h3 className="card-title">Latest Seller Ads</h3>
							</div>
							<div className="card-body pb-3">
								<div className="rated-products">
									<ul className="vertical-scroll">
										<li className="item">
											<div className="media m-0 mt-0 p-5">
												<img className="me-4" src="assets/images/products/toys.png" alt="img"/>
												<div className="media-body">
													<h4 className="mt-2 mb-1">Apartment</h4>
													<span className="rated-products-ratings">
														<i className="fa fa-star text-warning"> </i>
														<i className="fa fa-star text-warning"> </i>
														<i className="fa fa-star text-warning"> </i>
														<i className="fa fa-star text-warning"> </i>
														<i className="fa fa-star text-warning"> </i>
													</span>
													<div className="h5 mb-0 font-weight-semibold mt-1">$17 - $29</div>
												</div>
											</div>
										</li>
										<li className="item">
											<div className="media p-5 mt-0">
												<img className="me-4" src="assets/images/products/1.png" alt="img"/>
												<div className="media-body">
													<h4 className="mt-2 mb-1">Modren Apartment</h4>
													<span className="rated-products-ratings">
														<i className="fa fa-star text-warning"> </i>
														<i className="fa fa-star text-warning"> </i>
														<i className="fa fa-star text-warning"> </i>
														<i className="fa fa-star text-warning"> </i>
														<i className="fa fa-star-o text-warning"> </i>
													</span>
													<div className="h5 mb-0 font-weight-semibold mt-1">$22 - $45</div>
												</div>
											</div>
										</li>
										<li className="item">
											<div className="media p-5 mt-0">
												<img className=" me-4" src="assets/images/products/4.png" alt="img"/>
												<div className="media-body">
													<h4 className="mt-2 mb-1">3BHK Flat</h4>
													<span className="rated-products-ratings">
														<i className="fa fa-star text-warning"> </i>
														<i className="fa fa-star text-warning"> </i>
														<i className="fa fa-star text-warning"> </i>
														<i className="fa fa-star text-warning"> </i>
														<i className="fa fa-star-half-o text-warning"> </i>
													</span>
													<div className="h5 mb-0 font-weight-semibold mt-1">$35 - $72</div>
												</div>
											</div>
										</li>
										<li className="item">
											<div className="media p-5 mt-0">
												<img className=" me-4" src="assets/images/products/6.png" alt="img"/>
												<div className="media-body">
													<h4 className="mt-2 mb-1">2BHK Flat</h4>
													<span className="rated-products-ratings">
														<i className="fa fa-star text-warning"> </i>
														<i className="fa fa-star text-warning"> </i>
														<i className="fa fa-star text-warning"> </i>
														<i className="fa fa-star-half-o text-warning"> </i>
														<i className="fa fa-star-o text-warning"> </i>
													</span>
													<div className="h5 mb-0 font-weight-semibold mt-1">$12 - $21</div>
												</div>
											</div>
										</li>
										<li className="item">
											<div className="media  mb-0 p-5 mt-0">
												<img className=" me-4" src="assets/images/products/8.png" alt="img"/>
												<div className="media-body">
													<h4 className="mt-2 mb-1">Luxury House</h4>
													<span className="rated-products-ratings">
														<i className="fa fa-star text-warning"> </i>
														<i className="fa fa-star text-warning"> </i>
														<i className="fa fa-star text-warning"> </i>
														<i className="fa fa-star-o text-warning"> </i>
														<i className="fa fa-star-o text-warning"> </i>
													</span>
													<div className="h5 mb-0 font-weight-semibold mt-1">$89 - $97</div>
												</div>
											</div>
										</li>
									</ul>
								</div>
							</div>
						</div>
						<div className="card">
							<div className="card-header">
								<h3 className="card-title">Map location</h3>
							</div>
							<div className="card-body">
								<div className="map-header">
									<div className="map-header-layer" id="map2"></div>
								</div>
							</div>
						</div>
						<div className="card">
							<div className="card-header">
								<h3 className="card-title">Search Ads</h3>
							</div>
							<div className="card-body">
								<div className="form-group">
									<input type="text" className="form-control" id="text2" placeholder="What are you looking for?"/>
								</div>
								<div className="form-group">
									<select name="country" id="select-countries" className="form-control form-select  select2-show-search">
										<option value="1" selected>All Categories</option>
										<option value="2">RealEstate</option>
										<option value="3">Apartments</option>
										<option value="4">3BHK Flat</option>
										<option value="5">Homes</option>
										<option value="6">Luxury Rooms</option>
										<option value="7">Deluxe Houses</option>
										<option value="8">Duplex House</option>
										<option value="9">Luxury Rooms</option>
										<option value="10">2BHk Homes</option>
										<option value="11">Apartments</option>
										<option value="12">Duplex Houses</option>
										<option value="13">3BHK Flatss</option>
										<option value="14">2BHK Flats</option>
										<option value="15">Modren Houses</option>
									</select>
								</div>
								<div className="">
									<Link to="javascript:void(0);" className="btn  btn-primary">Search</Link>
								</div>
							</div>
						</div>
						<div className="card">
							<div className="card-header">
								<h3 className="card-title">Popular Tags</h3>
							</div>
							<div className="card-body">
								<div className="product-tags clearfix">
									<ul className="list-unstyled mb-0">
										<li><Link to="javascript:void(0);">RealEstate</Link></li>
										<li><Link to="javascript:void(0);">Homes</Link></li>
										<li><Link to="javascript:void(0);">3BHK Flatss</Link></li>
										<li><Link to="javascript:void(0);">2BHK Homes</Link></li>
										<li><Link to="javascript:void(0);">Luxury Rooms</Link></li>
										<li><Link to="javascript:void(0);">Apartments</Link></li>
										<li><Link to="javascript:void(0);">3BHK Flatss</Link></li>
										<li><Link to="javascript:void(0);">Homes</Link></li>
										<li><Link to="javascript:void(0);">Luxury House For Sale</Link></li>
										<li><Link to="javascript:void(0);">Apartments</Link></li>
										<li><Link to="javascript:void(0);" className="mb-0">Luxury Rooms</Link></li>
									</ul>
								</div>
							</div>
						</div>
						<div className="card">
							<div className="card-header">
								<h3 className="card-title">Latest Properties</h3>
							</div>
							<div className="card-body pb-3">
								<ul className="vertical-scroll">
									<li className="news-item">
										<table>
											<tr>
												<td><img src="assets/images/products/1.png" alt="img" className="w-8 border"/></td>
												<td className="ps-4"><h5 className="mb-1 ">Best New Model Houses</h5><Link to="javascript:void(0);" className="btn-link">View Details</Link><span className="float-end font-weight-bold">$17</span></td>
											</tr>
										</table>
									</li>
									<li className="news-item">
										<table>
											<tr>
												<td><img src="assets/images/products/2.png" alt="img" className="w-8 border"/></td>
												<td className="ps-4"><h5 className="mb-1 ">Trending New Model Houses</h5><Link to="javascript:void(0);" className="btn-link">View Details</Link><span className="float-end font-weight-bold">$17</span></td>
											</tr>
										</table>
									</li>
									<li className="news-item">
										<table>
											<tr>
												<td><img src="assets/images/products/3.png" alt="img" className="w-8 border" /></td>
												<td className="ps-4"><h5 className="mb-1 ">Best New Model Houses</h5><Link to="javascript:void(0);" className="btn-link">View Details</Link><span className="float-end font-weight-bold">$17</span></td>
											</tr>
										</table>
									</li>
									<li className="news-item">
										<table>
											<tr>
												<td><img src="assets/images/products/4.png" alt="img" className="w-8 border" /></td>
												<td className="ps-4"><h5 className="mb-1 ">Trending New Model Houses</h5><Link to="javascript:void(0);" className="btn-link">View Details</Link><span className="float-end font-weight-bold">$17</span></td>
											</tr>
										</table>
									</li>
									<li className="news-item">
										<table>
											<tr>
												<td><img src="assets/images/products/5.png" alt="img" className="w-8 border" /></td>
												<td className="ps-4"><h5 className="mb-1 ">Best New Model Houses</h5><Link to="javascript:void(0);" className="btn-link">View Details</Link><span className="float-end font-weight-bold">$17</span></td>
											</tr>
										</table>
									</li>
									<li className="news-item">
										<table>
											<tr>
												<td><img src="assets/images/products/6.png" alt="img" className="w-8 border" /></td>
												<td className="ps-4"><h5 className="mb-1 ">Duplex House</h5><Link to="javascript:void(0);" className="btn-link">View Details</Link><span className="float-end font-weight-bold">$17</span></td>
											</tr>
										</table>
									</li>
									<li className="news-item">
										<table>
											<tr>
												<td><img src="assets/images/products/7.png" alt="img" className="w-8 border" /></td>
												<td className="ps-4"><h5 className="mb-1 ">Modren Flats</h5><Link to="javascript:void(0);" className="btn-link">View Details</Link><span className="float-end font-weight-bold">$17</span></td>
											</tr>
										</table>
									</li>
								</ul>
							</div>
						</div>
					</div>
					{/* <!--/Right Side Content--> */}
				</div>
			</div>
		</section>

		{/* <!-- Newsletter--> */}
		<section className="sptb bg-white border-top">
			<div className="container">
				<div className="row">
					<div className="col-lg-7 col-xl-6 col-md-12">
						<div className="sub-newsletter">
							<h3 className="mb-2"><i className="fa fa-paper-plane-o me-2"></i> Subscribe To Our Newsletter</h3>
							<p className="mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor</p>
						</div>
					</div>
					<div className="col-lg-5 col-xl-6 col-md-12">
						<div className="input-group sub-input mt-1">
							<input type="text" className="form-control input-lg " placeholder="Enter your Email"/>
								<button type="button" className="btn btn-primary btn-lg br-tr-3  br-br-3">
									Subscribe
								</button>
						</div>
					</div>
				</div>
			</div>
		</section>
		{/* <!--/Newsletter--> */}

        <Footer/>

		{/* <!--Owl Carousel js --> */}
		{/* <script src="assets/plugins/owl-carousel/owl.carousel.js"></script> */}
		{/* <!-- Cover-image Js--> */}
		{/* <script src="assets/js/owl-carousel.js"></script> */}

    </Fragment>

	
  )
}

export default $2BHKHouse