import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'

function AvailableProperties() {
    return (
        <Fragment>
            {/* <!--Sliders Section--> */}
            <div>
                <div className="cover-image sptb-1 bg-background" data-bs-image-src="../assets/images/banners/banner1.jpg">
                    <div className="header-text1 mb-0">
                        <div className="container">
                            <div className="row">
                                <div className="col-xl-8 col-lg-12 col-md-12 d-block mx-auto">
                                    <div className="text-center text-white text-property">
                                        <h1 ><span className="font-weight-bold">16,25,365</span> Properties Available</h1>
                                    </div>
                                    <div className=" search-background bg-transparent">
                                        <div className="form row no-gutters">
                                            <div className="form-group  col-xl-6 col-lg-5 col-md-12 mb-0">
                                                <input type="text" className="form-control input-lg br-tr-md-0 br-br-md-0" id="text" placeholder="Search Property" />
                                            </div>
                                            <div className="form-group col-xl-4 col-lg-4 select2-lg col-md-12 mb-0">
                                                <select className="form-control select2-show-search w-100" data-placeholder="Select">
                                                    <optgroup label="Categories">
                                                        <option>Select</option>
                                                        <option value="1">Deluxe Houses</option>
                                                        <option value="2">2BHK Homes</option>
                                                        <option value="3">Apartments</option>
                                                        <option value="4">Modren Houses</option>
                                                        <option value="5">Job</option>
                                                        <option value="6">Luxury Rooms</option>
                                                        <option value="7">Luxury Rooms</option>
                                                        <option value="8">Duplex Houses</option>
                                                        <option value="9">3BHK Flatss</option>
                                                        <option value="10">3BHk Homes</option>
                                                    </optgroup>
                                                </select>
                                            </div>
                                            <div className="col-xl-2 col-lg-3 col-md-12 mb-0">
                                                <Link to="javascript:void(0);" className="btn btn-lg btn-block btn-primary br-bl-md-0 br-tl-md-0">Search</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* <!-- /header-text --> */}

                </div>
            </div>
            {/* <!--/Sliders Section--> */}

            {/* <!--BreadCrumb--> */}
            <div className="bg-white border-bottom">
                <div className="container">
                    <div className="page-header">
                        <h4 className="page-title">RealEstate</h4>
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="javascript:void(0);">Home</Link></li>
                            <li className="breadcrumb-item"><Link to="javascript:void(0);">Categories</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">RealEstate</li>
                        </ol>
                    </div>
                </div>
            </div>
            {/* <!--/BreadCrumb--> */}
        </Fragment>
    )
}

export default AvailableProperties