import { Fragment } from 'react'
import { Link } from 'react-router-dom'

const Bproperties = () => {
  return (
    <Fragment>
    <section className="sptb">
		    <div className="container">
				<div className="col-md-12">
					<div className="items-gallery">
						<div className="items-blog-tab text-center">
							<h2 className="">Best Property Collections</h2>
							<div className="items-blog-tab-heading">
								<div className="">
									<ul className="nav items-blog-tab-menu">
										<li className=""><Link to="#tab-1" className="active show" data-bs-toggle="tab">All</Link></li>
										<li><Link to="#tab-2" data-bs-toggle="tab" className="">2BHK Homes</Link></li>
										<li><Link to="#tab-3" data-bs-toggle="tab" className="">Villas</Link></li>
										<li><Link to="#tab-4" data-bs-toggle="tab" className="">Apartments</Link></li>
										<li><Link to="#tab-5" data-bs-toggle="tab" className="">Deplux Houses</Link></li>
									</ul>
								</div>
							</div>
							<div className="tab-content p-0">
								<div className="tab-pane active" id="tab-1">
									<div className="row pt-2" id="container3">
										<div className="col-xl-4 col-lg-4 col-md-12">
											<div className="card">
											    <span className="ribbon-2">
													<span><i className="fa fa-home"></i></span>
												</span>
												<div className="item-card8-img">
													<Link to="/$2BHKHouse"></Link>
													<img src="assets/images/products/R1.jpg" alt="img" className="cover-image"/>
												</div>
												<div className="item-card8-overlaytext">
													<h6 className="bg-primary  mb-0">Luxury Room</h6>
												</div>
												<div className="card-body">
													<div className="item-card8-desc">
														<p className="text-muted">16 June 2019.</p>
														<Link to="/$2BHKHouse"><h4 className="font-weight-semibold">Food &amp; Bar Apartments</h4></Link>
														<p className="mb-0">At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium</p>
													</div>
												</div>
											</div>
										</div>
										<div className="col-xl-4 col-lg-4 col-md-12">
											<div className="card">
												<span className="ribbon-3">
													<span><i className="fa fa-building"></i></span>
												</span>
												<div className="item-card8-img">
													<Link to="/$2BHKHouse"></Link>
													<img src="assets/images/products/R2.jpg" alt="img" className="cover-image"/>
												</div>
												<div className="item-card8-overlaytext">
													<h6 className="bg-info  mb-0">Villa</h6>
												</div>
												<div className="card-body">
													<div className="item-card8-desc">
														<p className="text-muted">05 June 2019</p>
														<Link to="/$2BHKHouse"><h4 className="font-weight-semibold">Food &amp; Bar Apartments</h4></Link>
														<p className="mb-0">At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium</p>
													</div>
												</div>
											</div>
										</div>
										<div className="col-xl-4 col-lg-4 col-md-12">
											<div className="card">
											    <span className="ribbon-2">
													<span><i className="fa fa-home"></i></span>
												</span>
												<div className="item-card8-img">
													<Link to="/$2BHKHouse"></Link>
													<img src="assets/images/products/R3.jpg" alt="img" className="cover-image"/>
												</div>
												<div className="item-card8-overlaytext">
													<h6 className="bg-primary  mb-0">Apartments</h6>
												</div>
												<div className="card-body">
													<div className="item-card8-desc">
														<p className="text-muted">21 June 2019</p>
														<Link to="/$2BHKHouse"><h4 className="font-weight-semibold">Food &amp; Bar Apartments</h4></Link>
														<p className="mb-0">At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium</p>
													</div>
												</div>
											</div>
										</div>
										<div className="col-xl-4 col-lg-4 col-md-12">
											<div className="card">
												<span className="ribbon-3">
													<span><i className="fa fa-building"></i></span>
												</span>
												<div className="item-card8-img">
													<Link to="/$2BHKHouse"></Link>
													<img src="assets/images/products/R4.jpg" alt="img" className="cover-image"/>
												</div>
												<div className="item-card8-overlaytext">
													<h6 className="bg-info  mb-0">Deplux Rooms</h6>
												</div>
												<div className="card-body">
													<div className="item-card8-desc">
														<p className="text-muted">20 June 2019</p>
														<Link to="/$2BHKHouse"><h4 className="font-weight-semibold">Food &amp; Bar Apartments</h4></Link>
														<p className="mb-0">At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium</p>
													</div>
												</div>
											</div>
										</div>
										<div className="col-xl-4 col-lg-4 col-md-12">
											<div className="card">
											    <span className="ribbon-1">
													<span><i className="fa fa-home"></i></span>
												</span>
												<div className="item-card8-img">
													<Link to="/$2BHKHouse"></Link>
													<img src="assets/images/products/R5.jpeg" alt="img" className="cover-image"/>
												</div>
												<div className="item-card8-overlaytext">
													<h6 className="bg-secondary mb-0">2BHK House</h6>
												</div>
												<div className="card-body">
													<div className="item-card8-desc">
														<p className="text-muted">11 June 2019 </p>
														<Link to="/$2BHKHouse"><h4 className="font-weight-semibold">Food &amp; Bar Apartments</h4></Link>
														<p className="mb-0">At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium</p>
													</div>
												</div>
											</div>
										</div>
										<div className="col-xl-4 col-lg-4 col-md-12">
											<div className="card">
											    <span className="ribbon-2">
													<span><i className="fa fa-building-o"></i></span>
												</span>
												<div className="item-card8-img">
													<Link to="/$2BHKHouse"></Link>
													<img src="assets/images/products/R6.jpeg" alt="img" className="cover-image"/>
												</div>
												<div className="item-card8-overlaytext">
													<h6 className="bg-primary  mb-0">Villa</h6>
												</div>
												<div className="card-body">
													<div className="item-card8-desc">
														<p className="text-muted">26 May 2019.</p>
														<Link to="/$2BHKHouse"><h4 className="font-weight-semibold">Food &amp; Bar Apartments</h4></Link>
														<p className="mb-0">At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium</p>
													</div>
												</div>
											</div>
										</div>
										<div className="col-xl-4 col-lg-4 col-md-12">
											<div className="card">
											    <span className="ribbon-3">
													<span><i className="fa fa-home"></i></span>
												</span>
												<div className="item-card8-img">
													<Link to="/$2BHKHouse"></Link>
													<img src="assets/images/products/R7.png" alt="img" className="cover-image"/>
												</div>
												<div className="item-card8-overlaytext">
													<h6 className="bg-info mb-0">Apartments</h6>
												</div>
												<div className="card-body">
													<div className="item-card8-desc">
														<p className="text-muted">18 June 2019</p>
														<Link to="/$2BHKHouse"><h4 className="font-weight-semibold">Food &amp; Bar Apartments</h4></Link>
														<p className="mb-0">At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium</p>
													</div>
												</div>
											</div>
										</div>
										<div className="col-xl-4 col-lg-4 col-md-12">
											<div className="card">
											    <span className="ribbon-1">
													<span><i className="fa fa-building"></i></span>
												</span>
												<div className="item-card8-img">
													<Link to="/$2BHKHouse"></Link>
													<img src="assets/images/products/R8.png" alt="img" className="cover-image"/>
												</div>
												<div className="item-card8-overlaytext">
													<h6 className="bg-secondary mb-0">Duplex House</h6>
												</div>
												<div className="card-body">
													<div className="item-card8-desc">
														<p className="text-muted">19 June 2019</p>
														<Link to="/$2BHKHouse"><h4 className="font-weight-semibold">Food &amp; Bar Apartments</h4></Link>
														<p className="mb-0">At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium</p>
													</div>
												</div>
											</div>
										</div>
										<div className="col-xl-4 col-lg-4 col-md-12">
											<div className="card">
											    <span className="ribbon-3">
													<span><i className="fa fa-home"></i></span>
												</span>
												<div className="item-card8-img">
													<Link to="/$2BHKHouse"></Link>
													<img src="assets/images/products/R10.jpg" alt="img" className="cover-image"/>
												</div>
												<div className="item-card8-overlaytext">
													<h6 className="bg-info mb-0">2BHK House</h6>
												</div>
												<div className="card-body">
													<div className="item-card8-desc">
														<p className="text-muted">10 June 2019.</p>
														<Link to="/$2BHKHouse"><h4 className="font-weight-semibold">Food &amp; Bar Apartments</h4></Link>
														<p className="mb-0">At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium</p>
													</div>
												</div>
											</div>
										</div>
										<div className="col-xl-4 col-lg-4 col-md-12">
											<div className="card mb-lg-0">
											    <span className="ribbon-1">
													<span><i className="fa fa-building-o"></i></span>
												</span>
												<div className="item-card8-img">
													<Link to="/$2BHKHouse"></Link>
													<img src="assets/images/products/R10.jpg" alt="img" className="cover-image"/>
												</div>
												<div className="item-card8-overlaytext">
													<h6 className="bg-secondary  mb-0">Villa</h6>
												</div>
												<div className="card-body">
													<div className="item-card8-desc">
														<p className="text-muted">16 November 2019.</p>
														<Link to="/$2BHKHouse"><h4 className="font-weight-semibold">Food &amp; Bar Apartments</h4></Link>
														<p className="mb-0">At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium</p>
													</div>
												</div>
											</div>
										</div>
										<div className="col-xl-4 col-lg-4 col-md-12">
											<div className="card mb-0">
											    <span className="ribbon-1">
													<span><i className="fa fa-home"></i></span>
												</span>
												<div className="item-card8-img">
													<Link to="/$2BHKHouse"></Link>
													<img src="assets/images/products/R10.jpg" alt="img" className="cover-image"/>
												</div>
												<div className="item-card8-overlaytext">
													<h6 className="bg-secondary  mb-0">Apartments</h6>
												</div>
												<div className="card-body">
													<div className="item-card8-desc">
														<p className="text-muted">06 July 2019</p>
														<Link to="/$2BHKHouse"><h4 className="font-weight-semibold">Food &amp; Bar Apartments</h4></Link>
														<p className="mb-0">At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium</p>
													</div>
												</div>
											</div>
										</div>
										<div className="col-xl-4 col-lg-4 col-md-12">
											<div className="card mb-0">
											    <span className="ribbon-2">
													<span><i className="fa fa-building"></i></span>
												</span>
												<div className="item-card8-img">
													<Link to="/$2BHKHouse"></Link>
													<img src="assets/images/products/R2.jpg" alt="img" className="cover-image"/>
												</div>
												<div className="item-card8-overlaytext">
													<h6 className="bg-primary  mb-0">Duplex Room</h6>
												</div>
												<div className="card-body">
													<div className="item-card8-desc">
														<p className="text-muted">13 June 2019</p>
														<Link to="/$2BHKHouse"><h4 className="font-weight-semibold">Food &amp; Bar Apartments</h4></Link>
														<p className="mb-0">At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium</p>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="tab-pane" id="tab-2">
									<div className="row">
										<div className="col-xl-4 col-lg-4 col-md-12">
											<div className="card mb-xl-0">
											    <span className="ribbon-2">
													<span><i className="fa fa-home"></i></span>
												</span>
												<div className="item-card8-img">
													<Link to="/$2BHKHouse"></Link>
													<img src="assets/images/products/R3.jpg" alt="img" className="cover-image"/>
												</div>
												<div className="item-card8-overlaytext">
													<h6 className="bg-primary  mb-0">Luxury Room</h6>
												</div>
												<div className="card-body">
													<div className="item-card8-desc">
														<p className="text-muted">16 June 2019.</p>
														<Link to="/$2BHKHouse"><h4 className="font-weight-semibold">Food &amp; Bar Apartments</h4></Link>
														<p className="mb-0">At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium</p>
													</div>
												</div>
											</div>
										</div>
										<div className="col-xl-4 col-lg-4 col-md-12">
											<div className="card mb-xl-0">
											    <span className="ribbon-1">
													<span><i className="fa fa-home"></i></span>
												</span>
												<div className="item-card8-img">
													<Link to="/$2BHKHouse"></Link>
													<img src="assets/images/products/R4.jpg" alt="img" className="cover-image"/>
												</div>
												<div className="item-card8-overlaytext">
													<h6 className="bg-secondary mb-0">2BHK House</h6>
												</div>
												<div className="card-body">
													<div className="item-card8-desc">
														<p className="text-muted">11 June 2019 </p>
														<Link to="/$2BHKHouse"><h4 className="font-weight-semibold">Food &amp; Bar Apartments</h4></Link>
														<p className="mb-0">At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium</p>
													</div>
												</div>
											</div>
										</div>
										<div className="col-xl-4 col-lg-4 col-md-12">
											<div className="card mb-0">
											    <span className="ribbon-3">
													<span><i className="fa fa-home"></i></span>
												</span>
												<div className="item-card8-img">
													<Link to="/$2BHKHouse"></Link>
													<img src="assets/images/products/R1.jpg" alt="img" className="cover-image"/>
												</div>
												<div className="item-card8-overlaytext">
													<h6 className="bg-info mb-0">Modren Kitchen</h6>
												</div>
												<div className="card-body">
													<div className="item-card8-desc">
														<p className="text-muted">10 June 2019.</p>
														<Link to="/$2BHKHouse"><h4 className="font-weight-semibold">Food &amp; Bar Apartments</h4></Link>
														<p className="mb-0">At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium</p>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="tab-pane" id="tab-3">
									<div className="row">
										<div className="col-xl-4 col-lg-4 col-md-12">
											<div className="card mb-xl-0">
												<span className="ribbon-3">
													<span><i className="fa fa-building"></i></span>
												</span>
												<div className="item-card8-img">
													<Link to="/$2BHKHouse"></Link>
													<img src="assets/images/products/R10.jpg" alt="img" className="cover-image"/>
												</div>
												<div className="item-card8-overlaytext">
													<h6 className="bg-info  mb-0">Villa</h6>
												</div>
												<div className="card-body">
													<div className="item-card8-desc">
														<p className="text-muted">05 June 2019</p>
														<Link to="/$2BHKHouse"><h4 className="font-weight-semibold">Food &amp; Bar Apartments</h4></Link>
														<p className="mb-0">At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium</p>
													</div>
												</div>
											</div>
										</div>
										<div className="col-xl-4 col-lg-4 col-md-12">
											<div className="card mb-xl-0">
											    <span className="ribbon-2">
													<span><i className="fa fa-building-o"></i></span>
												</span>
												<div className="item-card8-img">
													<Link to="/$2BHKHouse"></Link>
													<img src="assets/images/products/R1.jpg" alt="img" className="cover-image"/>
												</div>
												<div className="item-card8-overlaytext">
													<h6 className="bg-primary  mb-0">Garden House</h6>
												</div>
												<div className="card-body">
													<div className="item-card8-desc">
														<p className="text-muted">26 May 2019.</p>
														<Link to="/$2BHKHouse"><h4 className="font-weight-semibold">Food &amp; Bar Apartments</h4></Link>
														<p className="mb-0">At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium</p>
													</div>
												</div>
											</div>
										</div>
										<div className="col-xl-4 col-lg-4 col-md-12">
											<div className="card mb-0">
											    <span className="ribbon-1">
													<span><i className="fa fa-building-o"></i></span>
												</span>
												<div className="item-card8-img">
													<Link to="/$2BHKHouse"></Link>
													<img src="assets/images/products/R8.png" alt="img" className="cover-image"/>
												</div>
												<div className="item-card8-overlaytext">
													<h6 className="bg-secondary  mb-0">Villa</h6>
												</div>
												<div className="card-body">
													<div className="item-card8-desc">
														<p className="text-muted">16 November 2019.</p>
														<Link to="/$2BHKHouse"><h4 className="font-weight-semibold">Food &amp; Bar Apartments</h4></Link>
														<p className="mb-0">At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium</p>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="tab-pane" id="tab-4">
									<div className="row">
										<div className="col-xl-4 col-lg-4 col-md-12">
											<div className="card mb-xl-0">
											    <span className="ribbon-2">
													<span><i className="fa fa-home"></i></span>
												</span>
												<div className="item-card8-img">
													<Link to="/$2BHKHouse"></Link>
													<img src="assets/images/products/R9.jpg" alt="img" className="cover-image"/>
												</div>
												<div className="item-card8-overlaytext">
													<h6 className="bg-primary  mb-0">Apartments</h6>
												</div>
												<div className="card-body">
													<div className="item-card8-desc">
														<p className="text-muted">21 June 2019</p>
														<Link to="/$2BHKHouse"><h4 className="font-weight-semibold">Food &amp; Bar Apartments</h4></Link>
														<p className="mb-0">At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium</p>
													</div>
												</div>
											</div>
										</div>
										<div className="col-xl-4 col-lg-4 col-md-12">
											<div className="card mb-xl-0">
											    <span className="ribbon-3">
													<span><i className="fa fa-home"></i></span>
												</span>
												<div className="item-card8-img">
													<Link to="/$2BHKHouse"></Link>
													<img src="assets/images/products/R10.jpg" alt="img" className="cover-image"/>
												</div>
												<div className="item-card8-overlaytext">
													<h6 className="bg-info mb-0">2BHK House</h6>
												</div>
												<div className="card-body">
													<div className="item-card8-desc">
														<p className="text-muted">18 June 2019</p>
														<Link to="/$2BHKHouse"><h4 className="font-weight-semibold">Food &amp; Bar Apartments</h4></Link>
														<p className="mb-0">At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium</p>
													</div>
												</div>
											</div>
										</div>
										<div className="col-xl-4 col-lg-4 col-md-12">
											<div className="card mb-0">
											    <span className="ribbon-1">
													<span><i className="fa fa-home"></i></span>
												</span>
												<div className="item-card8-img">
													<Link to="/$2BHKHouse"></Link>
													<img src="assets/images/products/R1.jpg" alt="img" className="cover-image"/>
												</div>
												<div className="item-card8-overlaytext">
													<h6 className="bg-secondary  mb-0">3BHK House</h6>
												</div>
												<div className="card-body">
													<div className="item-card8-desc">
														<p className="text-muted">06 July 2019</p>
														<Link to="/$2BHKHouse"><h4 className="font-weight-semibold">Food &amp; Bar Apartments</h4></Link>
														<p className="mb-0">At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium</p>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="tab-pane" id="tab-5">
									<div className="row">
										<div className="col-xl-4 col-lg-4 col-md-12">
											<div className="card mb-xl-0">
											    <span className="ribbon-3">
													<span><i className="fa fa-building"></i></span>
												</span>
												<div className="item-card8-img">
													<Link to="/$2BHKHouse"></Link>
													<img src="assets/images/products/R2.jpg" alt="img" className="cover-image"/>
												</div>
												<div className="item-card8-overlaytext">
													<h6 className="bg-info  mb-0">Deplux Rooms</h6>
												</div>
												<div className="card-body">
													<div className="item-card8-desc">
														<p className="text-muted">20 June 2019</p>
														<Link to="/$2BHKHouse"><h4 className="font-weight-semibold">Food &amp; Bar Apartments</h4></Link>
														<p className="mb-0">At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium</p>
													</div>
												</div>
											</div>
										</div>
										<div className="col-xl-4 col-lg-4 col-md-12">
											<div className="card mb-xl-0">
											    <span className="ribbon-1">
													<span><i className="fa fa-building"></i></span>
												</span>
												<div className="item-card8-img">
													<Link to="/$2BHKHouse"></Link>
													<img src="assets/images/products/R3.jpg" alt="img" className="cover-image"/>
												</div>
												<div className="item-card8-overlaytext">
													<h6 className="bg-secondary mb-0">Duplex House</h6>
												</div>
												<div className="card-body">
													<div className="item-card8-desc">
														<p className="text-muted">19 June 2019</p>
														<Link to="/$2BHKHouse"><h4 className="font-weight-semibold">Food &amp; Bar Apartments</h4></Link>
														<p className="mb-0">At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium</p>
													</div>
												</div>
											</div>
										</div>
										<div className="col-xl-4 col-lg-4 col-md-12">
											<div className="card mb-0">
											    <span className="ribbon-2">
													<span><i className="fa fa-building"></i></span>
												</span>
												<div className="item-card8-img">
													<Link to="/$2BHKHouse"></Link>
													<img src="assets/images/products/R4.jpg" alt="img" className="cover-image"/>
												</div>
												<div className="item-card8-overlaytext">
													<h6 className="bg-primary  mb-0">Duplex Room</h6>
												</div>
												<div className="card-body">
													<div className="item-card8-desc">
														<p className="text-muted">13 June 2019</p>
														<Link to="/$2BHKHouse"><h4 className="font-weight-semibold">Food &amp; Bar Apartments</h4></Link>
														<p className="mb-0">At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium</p>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		    </section>
    </Fragment>
  )
}

export default Bproperties