import { Fragment } from 'react'
import { Link } from 'react-router-dom'

const Fproperties = () => {
  return (
    <Fragment>
        <section className="sptb bg-patterns">
				<div className="container">
					<div className="section-title center-block text-center">
						<h2>Featured Properties</h2>
						<p> The location of a property is a crucial factor for many homebuyers. Factors such as proximity to schools, workplaces, amenities, transportation, and desirable neighborhoods can greatly influence a property's appeal</p>
					</div>
					<div id="myCarousel2" className="owl-carousel owl-carousel-icons2">
						{/* <!-- Wrapper for carousel items --> */}
						<div className="item">
							<div className="card mb-0">
								<div className="arrow-ribbon bg-primary">For Sale</div>
								<div className="item-card7-imgs">
									<Link to="/$2BHKHouse"></Link>
									<img src="assets/images/products/products/f5.jpg" alt="img" className="cover-image" />
								</div>
								<div className="item-card7-overlaytext">
									<Link to="/$2BHKHouse" className="text-white"> Featured </Link>
									<span className="mb-0 fs-13 active"><i className="fa fa fa-heart"></i></span>
								</div>
								<div className="card-body">
									<div className="item-card7-desc">
										<div className="item-card7-text">
											<Link to="/$2BHKHouse" className="text-dark"><h4 className="javascript:void(0);">Deluxe Flat</h4></Link>
											<p className="javascript:void(0);"><i className="icon icon-location-pin text-muted me-1"></i> New York, NY 10012, US </p>
										</div>
										<ul className="item-cards7-ic mb-0">
											<li><Link to="javascript:void(0);"><i className="fa fa-arrows-alt text-muted me-1"></i> 300 Sqft</Link></li>
											<li><Link to="javascript:void(0);" className="icons"><i className="fa fa-bed text-muted me-1"></i> 4 Beds</Link></li>
											<li><Link to="javascript:void(0);" className="icons"><i className="fa fa-bath text-muted me-1"></i> 3 Bath</Link></li>
											<li><Link to="javascript:void(0);" className="icons"><i className="fa fa-car text-muted me-1"></i> 1 Car</Link></li>
										</ul>
										<h5 className="font-weight-bold mb-0">$89,005 <span className="fs-12  font-weight-normal">Per Month</span></h5>
									</div>
								</div>
								<div className="card-footer">
									<div className="d-flex mb-0">
										<span className="fs-12"><i className="icon icon-event me-2 mt-1"></i>Jul 10 2019 </span>
										<div className="ms-auhref">
											<Link to="javascript:void(0);" className="javascript:void(0);" data-bs-hrefggle="hrefoltip" data-bs-placement="hrefp" title="Share Property"><i className="icon icon-share text-muted"></i></Link>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="item">
							<div className="card mb-0">
								<div className="arrow-ribbon bg-purple">For Buy</div>
								<div className="item-card7-imgs">
									<Link to="/$2BHKHouse"></Link>
									<img src="assets/images/products/products/f6.jpg" alt="img" className="cover-image" />
								</div>
								<div className="item-card7-overlaytext">
									<Link to="/$2BHKHouse" className="text-white">Featured</Link>
									<span className="mb-0 fs-13"><i className="fa fa fa-heart-o"></i></span>
								</div>
								<div className="card-body">
									<div className="item-card7-desc">
										<div className="item-card7-text">
											<Link to="/$2BHKHouse" className="text-dark"><h4 className="javascript:void(0);">2BHk Deluxe Flat</h4></Link>
											<p className="javascript:void(0);"><i className="icon icon-location-pin text-muted me-1"></i> New York, NY 10012, US </p>
										</div>
										<ul className="item-cards7-ic mb-0">
											<li><Link to="javascript:void(0);"><i className="fa fa-arrows-alt text-muted me-1"></i> 300 Sqft</Link></li>
											<li><Link to="javascript:void(0);" className="icons"><i className="fa fa-bed text-muted me-1"></i> 4 Beds</Link></li>
											<li><Link to="javascript:void(0);" className="icons"><i className="fa fa-bath text-muted me-1"></i> 3 Bath</Link></li>
											<li><Link to="javascript:void(0);" className="icons"><i className="fa fa-car text-muted me-1"></i> 1 Car</Link></li>
										</ul>
										<h5 className="font-weight-bold mb-0">$89,005 <span className="fs-12  font-weight-normal">Per Month</span></h5>
									</div>
								</div>
								<div className="card-footer">
									<div className="d-flex mb-0">
										<span className="fs-12"><i className="icon icon-event me-2 mt-1"></i>Jul 05 2019 </span>
										<div className="ms-auhref">
											<Link to="javascript:void(0);" className="javascript:void(0);" data-bs-hrefggle="hrefoltip" data-bs-placement="hrefp" title="Share Property"><i className="icon icon-share text-muted"></i></Link>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="item">
							<div className="card mb-0">
								<div className="arrow-ribbon bg-secondary">For Rent</div>
								<div className="item-card7-imgs">
									<Link to="/$2BHKHouse"></Link>
									<img src="assets/images/products/products/f7.jpg" alt="img" className="cover-image" />
								</div>
								<div className="item-card7-overlaytext">
									<Link to="/$2BHKHouse" className="text-white">Featured</Link>
									<span className="mb-0 fs-13 active"><i className="fa fa fa-heart"></i></span>
								</div>
								<div className="card-body">
									<div className="item-card7-desc">
										<div className="item-card7-text">
											<Link to="/$2BHKHouse" className="text-dark"><h4 className="javascript:void(0);">House For Sale</h4></Link>
											<p className="javascript:void(0);"><i className="icon icon-location-pin text-muted me-1"></i> New York, NY 10012, US </p>
										</div>
										<ul className="item-cards7-ic mb-0">
											<li><Link to="javascript:void(0);"><i className="fa fa-arrows-alt text-muted me-1"></i> 300 Sqft</Link></li>
											<li><Link to="javascript:void(0);" className="icons"><i className="fa fa-bed text-muted me-1"></i> 4 Beds</Link></li>
											<li><Link to="javascript:void(0);" className="icons"><i className="fa fa-bath text-muted me-1"></i> 3 Bath</Link></li>
											<li><Link to="javascript:void(0);" className="icons"><i className="fa fa-car text-muted me-1"></i> 1 Car</Link></li>
										</ul>
										<h5 className="font-weight-bold mb-0">$89,005 <span className="fs-12  font-weight-normal">Per Month</span></h5>
									</div>
								</div>
								<div className="card-footer">
									<div className="d-flex mb-0">
										<span className="fs-12"><i className="icon icon-event me-2 mt-1"></i>June 29 2019 </span>
										<div className="ms-auhref">
											<Link to="javascript:void(0);" className="javascript:void(0);" data-bs-hrefggle="hrefoltip" data-bs-placement="hrefp" title="Share Property"><i className="icon icon-share text-muted"></i></Link>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="item">
							<div className="card mb-0">
								<div className="arrow-ribbon bg-primary">For Sale</div>
								<div className="item-card7-imgs">
									<Link to="/$2BHKHouse"></Link>
									<img src="assets/images/products/products/f2.jpg" alt="img" className="cover-image" />
								</div>
								<div className="item-card7-overlaytext">
									<Link to="/$2BHKHouse" className="text-white"> Featured</Link>
									<span className="mb-0 fs-13"><i className="fa fa fa-heart-o"></i></span>
								</div>
								<div className="card-body">
									<div className="item-card7-desc">
										<div className="item-card7-text">
											<Link to="/$2BHKHouse" className="text-dark"><h4 className="javascript:void(0);">Office For Rent</h4></Link>
											<p className="javascript:void(0);"><i className="icon icon-location-pin text-muted me-1"></i> New York, NY 10012, US </p>
										</div>
										<ul className="item-cards7-ic mb-0">
											<li><Link to="javascript:void(0);"><i className="fa fa-arrows-alt text-muted me-1"></i> 300 Sqft</Link></li>
											<li><Link to="javascript:void(0);" className="icons"><i className="fa fa-bed text-muted me-1"></i> 4 Beds</Link></li>
											<li><Link to="javascript:void(0);" className="icons"><i className="fa fa-bath text-muted me-1"></i> 3 Bath</Link></li>
											<li><Link to="javascript:void(0);" className="icons"><i className="fa fa-car text-muted me-1"></i> 1 Car</Link></li>
										</ul>
										<h5 className="font-weight-bold mb-0">$89,005 <span className="fs-12  font-weight-normal">Per Month</span></h5>
									</div>
								</div>
								<div className="card-footer">
									<div className="d-flex mb-0">
										<span className="fs-12"><i className="icon icon-event me-2 mt-1"></i>June 25 2019 </span>
										<div className="ms-auhref">
											<Link to="javascript:void(0);" className="javascript:void(0);" data-bs-hrefggle="hrefoltip" data-bs-placement="hrefp" title="Share Property"><i className="icon icon-share text-muted"></i></Link>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="item sold-out">
							<div className="ribbon sold-ribbon ribbon-hrefp-left text-danger"><span className="bg-danger">Sold Out</span></div>
							<div className="card mb-0">
								<div className="arrow-ribbon bg-secondary">For Rent</div>
								<div className="item-card7-imgs">
									<Link to="/$2BHKHouse"></Link>
									<img src="assets/images/products/products/f8.jpg" alt="img" className="cover-image" />
								</div>
								<div className="item-card7-overlaytext">
									<Link to="/$2BHKHouse" className="text-white">Featured</Link>
									<span className="mb-0 fs-13 active"><i className="fa fa fa-heart"></i></span>
								</div>
								<div className="card-body">
									<div className="item-card7-desc">
										<div className="item-card7-text ">
											<Link to="/$2BHKHouse" className="text-dark"><h4 className="javascript:void(0);">Modern House For Sale</h4></Link>
											<p className="javascript:void(0);"><i className="icon icon-location-pin text-muted me-1"></i> New York, NY 10012, US </p>
										</div>
										<ul className="item-cards7-ic mb-0">
											<li><Link to="javascript:void(0);"><i className="fa fa-arrows-alt text-muted me-1"></i> 300 Sqft</Link></li>
											<li><Link to="javascript:void(0);" className="icons"><i className="fa fa-bed text-muted me-1"></i> 4 Beds</Link></li>
											<li><Link to="javascript:void(0);" className="icons"><i className="fa fa-bath text-muted me-1"></i> 3 Bath</Link></li>
											<li><Link to="javascript:void(0);" className="icons"><i className="fa fa-car text-muted me-1"></i> 1 Car</Link></li>
										</ul>
										<h5 className="font-weight-bold mb-0">$89,005 <span className="fs-12  font-weight-normal">Per Month</span></h5>
									</div>
								</div>
								<div className="card-footer">
									<div className="d-flex mb-0">
										<span className="fs-12"><i className="icon icon-event me-2 mt-1"></i>June 19 2019 </span>
										<div className="ms-auhref">
											<Link to="javascript:void(0);" className="javascript:void(0);" data-bs-hrefggle="hrefoltip" data-bs-placement="hrefp" title="Share Property"><i className="icon icon-share text-muted"></i></Link>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
    </Fragment>
  )
}

export default Fproperties