import { Fragment } from 'react'

const Categoriesmenu = () => {
	return (
		<Fragment>

			{/* <!--Categories  Menu --> */}
			<section className="categories">
				<div className="container">
					<div className="card mb-0 box-shadow-0">
						<div className="card-body">
							<div className="row">
								<div className="col-lg-3 col-md-6 col-sm-6 d-catmb mb-4 mb-lg-0">
									<div className="d-flex">
										<div>
											<span className="bg-primary-transparent icon-service1 text-primary">
												<i className="fa fa-map-o"></i>
											</span>
										</div>
										<div className="ms-4 mt-1">
											<h3 className=" mb-0 font-weight-bold">1,200</h3>
											<p className="mb-0 text-muted">Commercial Lands</p>
										</div>
									</div>
								</div>
								<div className="col-lg-3 col-md-6 col-sm-6 d-catmb mb-4 mb-lg-0">
									<div className="d-flex">
										<div>
											<span className="bg-secondary-transparent icon-service1 text-secondary">
												<i className="fa fa-home"></i>
											</span>
										</div>
										<div className="ms-4 mt-1">
											<h3 className=" mb-0 font-weight-bold">894</h3>
											<p className="mb-0 text-muted">Showrooms & Shops</p>
										</div>
									</div>
								</div>
								<div className="col-lg-3 col-md-6 col-sm-6 d-catmb mb-4 mb-sm-0">
									<div className="d-flex">
										<div>
											<span className="bg-warning-transparent icon-service1 text-warning">
												<i className="fa fa-object-group"></i>
											</span>
										</div>
										<div className="ms-4 mt-1">
											<h3 className=" mb-0 font-weight-bold">1,089</h3>
											<p className="mb-0 text-muted">Office rooms</p>
										</div>
									</div>
								</div>
								<div className="col-lg-3 col-md-6 col-sm-6 ">
									<div className="d-flex">
										<div>
											<span className="bg-info-transparent icon-service1 text-info">
												<i className="fa fa-building-o"></i>
											</span>
										</div>
										<div className="ms-4 mt-1">
											<h3 className=" mb-0 font-weight-bold ">789</h3>
											<p className="mb-0 text-muted">Residential</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* <!--Categories Menu--> */}
		</Fragment>
	)
}

export default Categoriesmenu