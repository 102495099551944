// Component
import React from 'react';
// import { Switch, Route } from 'react-router-dom';

//Pages
import Home from './pages/home';
import About from './pages/About';
import Register from './pages/Register';
import Contact from './pages/Contact';
import PropertiesPage from './pages/PropertiesPage';
import  $2BHKHouse from './pages/2BHK-House'
import Adpost from './pages/Adpost';
import MyDashboard from './pages/MyDashboard'

import { BrowserRouter, Route, Routes } from 'react-router-dom'




function App() {
     return (
          <div className='App'>
               <BrowserRouter>
                    <Routes>
                         <Route excat path='/' element={<Home />} />
                         <Route  path='/about' element={<About />} />
                         <Route  path='/Register' element={<Register />} />
                         <Route  path='/Contact' element={<Contact/>}/>
                         <Route  path='/PropertiesPage' element={<PropertiesPage/>}/>
                         <Route  path='/$2BHKHouse' element={<$2BHKHouse/>}/>
                         <Route  path='/Adpost' element={<Adpost/>}/>
                         <Route path='MyDashboard'  element={<MyDashboard/>}/>
                    </Routes>

               </BrowserRouter>

          </div>
     )
}

export default App;





