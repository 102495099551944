import React, { Fragment } from 'react'
import Footer from './Component/Footer'
import HeaderContact from './Component/HeaderContact'
import { Link } from 'react-router-dom'

const Contact = () => {
  return (
    <Fragment>
        {/* <!--Header--> */}
          <HeaderContact/>
         {/* <!--Header--> */}

		 {/* <!--Breadcrumb--> */}
		<div>
			<div className="bannerimg cover-image bg-background3" data-bs-image-src="../assets/images/banners/banner2.jpg">
				<div className="header-text mb-0">
					<div className="container">
						<div className="text-center text-white ">
							<h1 className="">Contact Us</h1>
							<ol className="breadcrumb text-center">
								<li className="breadcrumb-item"><Link to="javascript:void(0);">Home</Link></li>
								<li className="breadcrumb-item active text-white" aria-current="page">Contact</li>
							</ol>
						</div>
					</div>
				</div>
			</div>
		</div>
		{/* <!--/Breadcrumb--> */}

		<section className="sptb bg-white">
			<div className="container">
				<div className="row">
					<div className="col-md-12">
						<div className="row text-white">
							<div className="col-lg-6 col-md-12">
								<div className="card">
									<div className="support-service bg-primary">
										<i className="fa fa-phone"></i>
										<h6>+68 872-627-9735</h6>
										<p>Free Support!</p>
									</div>
								</div>
							</div>
							<div className="col-lg-6 col-md-12">
								<div className="card">
									<div className="support-service bg-secondary">
										<i className="fa fa-clock-o"></i>
										<h6>Mon-Sat(10:00-19:00)</h6>
										<p>Working Hours!</p>
									</div>
								</div>
							</div>
							<div className="col-lg-6 col-md-12">
								<div className="card">
									<div className="support-service bg-warning">
										<i className="fa fa-map-marker"></i>
										<h6>872 School Street Opa Locka, TN 37072</h6>
										<p> New York, USA</p>
									</div>
								</div>
							</div>
							<div className="col-lg-6 col-md-12">
								<div className="card mb-0">
									<div className="support-service bg-info">
										<i className="fa fa-envelope-o"></i>
										<h6>yourdomain@gmail.com</h6>
										<p>Support us!</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>

		{/* <!--Contact--> */}
		<div className="sptb">
			<div className="container">
				<div className="row">
					<div className="col-lg-6 col-xl-6 col-md-12">
					    <div className="map1">
							<div className="map-header-layer" id="map2"></div>
						</div>
					</div>
				    <div className="col-lg-6 col-xl-6 col-md-12">
						<div className="card mb-0">
							<div className="card-body">
								<div className="form-group">
									<input type="text" className="form-control" id="name1" placeholder="Your Name"/>
								</div>
								<div className="form-group">
									<input type="email" className="form-control" id="email" placeholder="Email Address"/>
								</div>
								<div className="form-group">
									<textarea className="form-control" name="example-textarea-input" rows="6" cols="" placeholder="Message"></textarea>
								</div>
								<div className="text-start">
									<Link to="javascript:void(0);" className="btn btn-primary">Send Message</Link>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		{/* <!--Contact--> */}
	


       {/* <!--Footer Section--> */}
		<Footer/>
		{/* <!--Footer Section--> */}
    </Fragment>
  )
}

export default Contact