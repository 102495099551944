import React, { Fragment } from 'react'
import Footer from './Component/Footer'
import { Link } from 'react-router-dom'
import HeaderAbout from './Component/HeaderAbout'

function about() {
  return (
    <Fragment>
         {/* Header Section */}
        <HeaderAbout/>
        {/* <!-- Page Direction --> */}
        <section>
            <div className="bannerimg cover-image bg-background3 sptb-2" data-bs-image-src="assets/images/banners/banner2.jpg">
                <div className="header-text mb-0">
                    <div className="container">
                        <div className="text-center text-white ">
                            <h1 className="">About Us</h1>
                            <ol className="breadcrumb text-center">
                                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                <li className="breadcrumb-item active text-white" aria-current="page">About Us</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
        </section>
         {/* <!-- Page Direction --> */}

         {/* <!--section--> */}
		<section className="sptb">
			<div className="container">
				<div className="text-justify">
					<h2 className="mb-4">Why Reallist?</h2>
					<h4 className="leading-normal">Majority have suffered alteration in some form, by injected humor</h4>
					<p className="leading-normal">There are many variations of passages of Lorem Ipsum available, but the majority have suffered  by injected humour, or randomised words which don't look even slightly believable.
					If you are going to use a passage of Lorem Ipsum, you need to as necessary All the Lorem Ipsum generators on the Internet tend to repeat</p>
					<p className="leading-normal">It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English.</p>
					<p className="leading-normal mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
				</div>
			</div>
		</section>
		{/* <!--/section--> */}

        {/* <!--How to work--> */}
		<section className="sptb bg-white">
			<div className="container">
				<div className="section-title center-block text-center">
					<h2>How It Works?</h2>
					<p>Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua</p>
				</div>
				<div className="row">
					<div className="col-lg-3 col-md-6 col-sm-6">
						<div className="">
							<div className="mb-lg-0 mb-4">
								<div className="service-card text-center">
									<div className="bg-white icon-bg box-shadow icon-service  about">
										<img src="assets/images/products/about/employees.png" alt="img"/>
									</div>
									<div className="servic-data mt-3">
										<h4 className="font-weight-semibold mb-2">Register</h4>
										<p className="text-muted mb-0">Nam libero tempore, cum soluta nobis est eligendi cumque facere possimus</p>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="col-lg-3 col-md-6 col-sm-6">
						<div className="">
							<div className="mb-lg-0 mb-4">
								<div className="service-card text-center">
									<div className="bg-white icon-bg box-shadow icon-service  about">
										<img src="assets/images/products/about/megaphone.png" alt="img"/>
									</div>
									<div className="servic-data mt-3">
										<h4 className="font-weight-semibold mb-2">Create Account</h4>
										<p className="text-muted mb-0">Nam libero tempore, cum soluta nobis est eligendi cumque facere possimus</p>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="col-lg-3 col-md-6 col-sm-6">
						<div className="">
							<div className="mb-sm-0 mb-4">
								<div className="service-card text-center">
									<div className="bg-white icon-bg box-shadow icon-service  about">
										<img src="assets/images/products/about/pencil.png" alt="img"/>
									</div>
									<div className="servic-data mt-3">
										<h4 className="font-weight-semibold mb-2">Add Posts</h4>
										<p className="text-muted mb-0">Nam libero tempore, cum soluta nobis est eligendi cumque facere possimus</p>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="col-lg-3 col-md-6 col-sm-6">
						<div className="">
							<div className="">
								<div className="service-card text-center">
									<div className="bg-white icon-bg box-shadow icon-service  about">
										<img src="assets/images/products/about/coins.png" alt="img"/>
									</div>
									<div className="servic-data mt-3">
										<h4 className="font-weight-semibold mb-2">Get Earnings</h4>
										<p className="text-muted mb-0">Nam libero tempore, cum soluta nobis est eligendi cumque facere possimus</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
		{/* <!--/How to work--> */}

        {/* <!--post section--> */}
		<section>
			<div className="cover-image sptb bg-background-color" data-bs-image-src="../assets/images/banners/banner4.jpg">
				<div className="content-text mb-0">
					<div className="container">
						<div className="text-center text-white ">
							<h2 className="mb-2 display-5">Are you ready for the posting you ads on this Site?</h2>
							<p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
							<div className="mt-5">
								<Link to="ad-posts.html" className="btn btn-secondary  btn-lg">Free Property Ad</Link>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
		{/* <!--/post section--> */}

        {/* <!--section--> */}
		<section className="sptb">
			<div className="container">
				<div className="section-title center-block text-center">
					<h2>Why Choose Us?</h2>
					<p>Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua</p>
				</div>
				<div className="row ">
					<div className="col-sm-6 col-lg-4 features">
						<div className="card">
							<div className="card-body text-center">
								<div className="feature">
									<div className="fa-stack fa-lg  fea-icon bg-success  mb-3">
										<i className="fa fa-university  text-white"></i>
									</div>
									<h3>Bank Loan Facility</h3>
									<p>our being able to do what we like best, every pleasure is to be welcomed and every pain.</p>
								</div>
							</div>
						</div>
					</div>
					<div className="col-sm-6 col-lg-4 features">
						<div className="card">
							<div className="card-body text-center">
								<div className="feature">
									<div className="fa-stack fa-lg  fea-icon bg-primary mb-3">
										<i className="fa fa-pencil-square-o  text-white"></i>
									</div>
									<h3>Spot Registration</h3>
									<p>our being able to do what we like best, every pleasure is to be welcomed and every pain.</p>
								</div>
							</div>
						</div>
					</div>
					<div className="col-sm-6 col-lg-4 features">
						<div className="card">
							<div className="card-body text-center">
								<div className="feature">
									<div className="fa-stack fa-lg  fea-icon bg-secondary mb-3">
										<i className="fa fa-handshake-o  text-white"></i>
									</div>
									<h3>Investment Consulting</h3>
									<p>our being able to do what we like best, every pleasure is to be welcomed and every pain.</p>
								</div>
							</div>
						</div>
					</div>
					<div className="col-sm-6 col-lg-4 features">
						<div className="card mb-lg-0">
							<div className="card-body text-center">
								<div className="feature">
									<div className="fa-stack fa-lg  fea-icon bg-warning mb-3">
										<i className="fa fa-cubes   text-white"></i>
									</div>
									<h3>Construction Services</h3>
									<p>our being able to do what we like best, every pleasure is to be welcomed and every pain.</p>
								</div>
							</div>
						</div>
					</div>
					<div className="col-sm-6 col-lg-4 features">
						<div className="card mb-lg-0 mb-md-0">
							<div className="card-body text-center">
								<div className="feature">
									<div className="fa-stack fa-lg  fea-icon bg-danger mb-3">
										<i className="fa fa-cogs   text-white"></i>
									</div>
									<h3>Personalized Service</h3>
									<p>our being able to do what we like best, every pleasure is to be welcomed and every pain.</p>
								</div>
							</div>
						</div>
					</div>
					<div className="col-sm-6 col-lg-4 features">
						<div className="card mb-0">
							<div className="card-body text-center">
								<div className="feature">
									<div className="fa-stack fa-lg  fea-icon bg-info mb-3">
										<i className="fa fa-flask  text-white"></i>
									</div>
									<h3>Property Maintenance</h3>
									<p>our being able to do what we like best, every pleasure is to be welcomed and every pain.</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
		{/* <!--/section--> */}

        {/* <!--Statistics--> */}
		<section>
			<div className="about-1 cover-image sptb bg-background-color" data-bs-image-src="../assets/images/banners/banner5.jpg">
				<div className="content-text mb-0 text-white info">
					<div className="container">
						<div className="row text-center">
							<div className="col-lg-3 col-md-6">
								<div className="counter-status md-mb-0">
									<div className="counter-icon text-danger">
										<i className="icon icon-people"></i>
									</div>
									<h5>Total Agents</h5>
									<h2 className="counter mb-0 font-weight-bold">893</h2>
								</div>
							</div>
							<div className="col-lg-3 col-md-6">
								<div className="counter-status status-1 md-mb-0">
									<div className="counter-icon text-warning">
										<i className="icon icon-rocket"></i>
									</div>
									<h5>Total Sales</h5>
									<h2 className="counter mb-0 font-weight-bold">1765</h2>
								</div>
							</div>
							<div className="col-lg-3 col-md-6">
								<div className="counter-status status md-mb-0">
									<div className="counter-icon text-primary">
										<i className="icon icon-docs"></i>
									</div>
									<h5>Total Projects</h5>
									<h2 className="counter mb-0 font-weight-bold">846</h2>
								</div>
							</div>
							<div className="col-lg-3 col-md-6">
								<div className="counter-status status">
									<div className="counter-icon text-success">
										<i className="icon icon-emotsmile"></i>
									</div>
									<h5>Happy Customers</h5>
									<h2 className="counter font-weight-bold">7253</h2>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
		{/* <!--/Statistics--> */}

        {/* <!--section--> */}
		<section className="sptb bg-white">
			<div className="container">
				<div className="section-title center-block text-center">
					<h2>Our Team</h2>
					<p>Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua</p>
				</div>
				<div className="row">
					<div className="col-xl-4 col-md-12">
						<div className="card mb-xl-0">
							<div className="card-body">
								<div className="team-section text-center">
									<div className="team-img">
										<img src="assets/images/faces/male/1.jpg" className="img-thumbnail rounded-circle alt=" alt=""/>
									</div>
									<h4 className="font-weight-bold dark-grey-text mt-4">Tracey	Poole</h4>
									<h6 className="text-muted mb-3">RealEstate Agent</h6>
									<p className="font-weight-normal dark-grey-text">
									<i className="fa fa-quote-left pe-2"></i>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quod eos id officiis hic tenetur quae quaerat ad velit ab hic tenetur.</p>
									<div className="text-warning">
										<i className="fa fa-star"> </i>
										<i className="fa fa-star"> </i>
										<i className="fa fa-star"> </i>
										<i className="fa fa-star"> </i>
										<i className="fa fa-star-half-full"> </i>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="col-xl-4 col-md-12">
						<div className="card mb-xl-0">
							<div className="card-body">
								<div className="team-section text-center">
									<div className="team-img">
										<img src="assets/images/faces/female/1.jpg" className="img-thumbnail rounded-circle alt=" alt=""/>
									</div>
									<h4 className="font-weight-bold dark-grey-text mt-4">Harry	Walker</h4>
									<h6 className="text-muted mb-3">RealEstate Builder</h6>
									<p className="font-weight-normal dark-grey-text">
									<i className="fa fa-quote-left pe-2"></i>Ut enim ad minima veniam, quis nostrum exercitationem ullam quis nostrum  corporis suscipit laboriosam, nisi ut aliquid commodi.</p>
									<div className="text-warning">
										<i className="fa fa-star"> </i>
										<i className="fa fa-star"> </i>
										<i className="fa fa-star"> </i>
										<i className="fa fa-star"> </i>
										<i className="fa fa-star"> </i>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="col-xl-4 col-md-12">
						<div className="card mb-0">
							<div className="card-body">
								<div className="team-section text-center">
									<div className="team-img">
										<img src="assets/images/faces/male/2.jpg" className="img-thumbnail rounded-circle alt=" alt=""/>
									</div>
									<h4 className="font-weight-bold dark-grey-text mt-4">Paul White</h4>
									<h6 className="text-muted mb-3">RealEstate Owner</h6>
									<p className="font-weight-normal dark-grey-text">
									<i className="fa fa-quote-left pe-2"></i>Luxury Home  For Sale odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti.</p>
									<div className="text-warning">
										<i className="fa fa-star"> </i>
										<i className="fa fa-star"> </i>
										<i className="fa fa-star"> </i>
										<i className="fa fa-star"> </i>
										<i className="fa fa-star-o"> </i>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
		{/* <!--/section--> */}


        {/* <!-- Newsletter--> */}
		<section className="sptb border-top">
			<div className="container">
				<div className="row">
					<div className="col-lg-7 col-xl-6 col-md-12">
						<div className="sub-newsletter">
							<h3 className="mb-2"><i className="fa fa-paper-plane-o me-2"></i> Subscribe To Our Newsletter</h3>
							<p className="mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor</p>
						</div>
					</div>
					<div className="col-lg-5 col-xl-6 col-md-12">
						<div className="input-group sub-input mt-1">
							<input type="text" className="form-control input-lg " placeholder="Enter your Email"/>
								<button type="button" className="btn btn-primary btn-lg br-tr-3  br-br-3">
									Subscribe
								</button>
						</div>
					</div>
				</div>
			</div>
		</section>
		{/* <!--/Newsletter--> */}





        {/* Footer Section */}
        <Footer/>
    </Fragment>
  )
}

export default about