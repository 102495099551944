import { Fragment } from 'react'
import { Link } from 'react-router-dom'

const Categories = () => {
	return (
		<Fragment>
			{/* <!--Categories--> */}
			<section className="sptb">
				<div className="container">
					<div className="section-title center-block text-center">
						<h2>Housing Categories</h2>
						<p>Home categories can vary depending on the context, but here are some common categories related to homes and housing</p>
					</div>
					<div id="small-categories" className="owl-carousel">
						<div className="item">
							<div className="card mb-0">
								<div className="item-card">
									<div className="item-card-desc">
										<Link to="javascript:void(0);"></Link>
										<div className="item-card-img">
											<img src="assets/images/products/1.png" alt="img" className="br-tr-7 br-tl-7" />
										</div>
										<div className="item-card-text">
											<h4 className="mb-0">Stylish Houses</h4>
											<span className="badge rounded-pill badge-primary w-15">45</span>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="item">
							<div className="card mb-0">
								<div className="item-card">
									<div className="item-card-desc">
										<Link to="javascript:void(0);"></Link>
										<div className="item-card-img">
											<img src="assets/images/products/2.png" alt="img" className="br-tr-7 br-tl-7" />
										</div>
										<div className="item-card-text">
											<h4 className="mb-0">Luxury Houses</h4>
											<span className="badge rounded-pill badge-secondary w-15">23</span>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="item">
							<div className="card mb-0">
								<div className="item-card">
									<div className="item-card-desc">
										<Link to="javascript:void(0);"></Link>
										<div className="item-card-img">
											<img src="assets/images/products/3.png" alt="img" className="br-tr-7 br-tl-7" />
										</div>
										<div className="item-card-text">
											<h4 className="mb-0">Apartments</h4>
											<span className="badge rounded-pill badge-warning w-15">48</span>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="item">
							<div className="card mb-0">
								<div className="item-card">
									<div className="item-card-desc">
										<Link to="javascript:void(0);"></Link>
										<div className="item-card-img">
											<img src="assets/images/products/9.png" alt="img" className="br-tr-7 br-tl-7" />
										</div>
										<div className="item-card-text">
											<h4 className="mb-0">Deluxe Houses</h4>
											<span className="badge rounded-pill badge-info w-15">15</span>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="item">
							<div className="card mb-0">
								<div className="item-card">
									<div className="item-card-desc">
										<Link to="javascript:void(0);"></Link>
										<div className="item-card-img">
											<img src="assets/images/products/10.png" alt="img" className="br-tr-7 br-tl-7" />
										</div>
										<div className="item-card-text">
											<h4 className="mb-0">Nature Houses</h4>
											<span className="badge rounded-pill badge-success w-15">12</span>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="item">
							<div className="card mb-0">
								<div className="item-card ">
									<div className="item-card-desc">
										<Link to="javascript:void(0);"></Link>
										<div className="item-card-img">
											<img src="assets/images/products/11.png" alt="img" className="br-tr-7 br-tl-7" />
										</div>
										<div className="item-card-text">
											<h4 className="mb-0">2BHK Houses</h4>
											<span className="badge rounded-pill badge-pink w-15">05</span>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="item">
							<div className="card mb-0 mb-0">
								<div className="item-card">
									<div className="item-card-desc">
										<Link to="javascript:void(0);"></Link>
										<div className="item-card-img">
											<img src="assets/images/products/3.png" alt="img" className="br-tr-7 br-tl-7" />
										</div>
										<div className="item-card-text">
											<h4 className="mb-0">3BK Houses</h4>
											<span className="badge rounded-pill badge-primary w-15">09</span>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="item">
							<div className="card mb-0">
								<div className="item-card">
									<div className="item-card-desc">
										<Link to="javascript:void(0);"></Link>
										<div className="item-card-img">
											<img src="assets/images/products/12.png" alt="img" className="br-tr-7 br-tl-7" />
										</div>
										<div className="item-card-text">
											<h4 className="mb-0">Duplex House</h4>
											<span className="badge rounded-pill badge-secondary w-15">65</span>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="item">
							<div className="card mb-0">
								<div className="item-card">
									<div className="item-card-desc">
										<Link to="javascript:void(0);"></Link>
										<div className="item-card-img">
											<img src="assets/images/products/13.png" alt="img" className="br-tr-7 br-tl-7" />
										</div>
										<div className="item-card-text">
											<h4 className="mb-0">Office Houses</h4>
											<span className="badge rounded-pill badge-warning w-15">45</span>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="item">
							<div className="card mb-0">
								<div className="item-card">
									<div className="item-card-desc">
										<Link to="javascript:void(0);"></Link>
										<div className="item-card-img">
											<img src="assets/images/products/14.png" alt="img" className="br-tr-7 br-tl-7" />
										</div>
										<div className="item-card-text">
											<h4 className="mb-0">KidsFriendly Houses</h4>
											<span className="badge rounded-pill badge-info w-15">36</span>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="item">
							<div className="card mb-0">
								<div className="item-card">
									<div className="item-card-desc">
										<Link to="javascript:void(0);"></Link>
										<div className="item-card-img">
											<img src="assets/images/products/4.png" alt="img" className="br-tr-7 br-tl-7" />
										</div>
										<div className="item-card-text">
											<h4 className="mb-0">Budget Homes</h4>
											<span className="badge rounded-pill badge-success w-15">59</span>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* <!--/Categories--> */}
		</Fragment>
	)
}

export default Categories