import React, { Fragment } from 'react'
import Topbar from './Component/Topbar'
import Footer from './Component/Footer'

const MyDashboard = () => {
  return (
    <Fragment>
        {/* <!--Topbar--> */}
		<Topbar/>

		{/* <!--Breadcrumb--> */}
		<section>
			<div className="bannerimg cover-image bg-background3" data-bs-image-src="assets/images/banners/banner2.jpg">
				<div className="header-text mb-0">
					<div className="container">
						<div className="text-center text-white">
							<h1 className="">My Dashboard</h1>
							<ol className="breadcrumb text-center">
								<li className="breadcrumb-item"><a href="javascript:void(0);">Home</a></li>
								<li className="breadcrumb-item active text-white" aria-current="page">My Dashboard</li>
							</ol>
						</div>
					</div>
				</div>
			</div>
		</section>
		{/* <!--Breadcrumb--> */}

		{/* <!--User Dashboard--> */}
		<section className="sptb">
			<div className="container">
				<div className="row">
					<div className="col-xl-3 col-lg-12 col-md-12">
						<div className="card overflow-hidden">
							<div className="card-header">
								<h3 className="card-title">My Dashboard</h3>
							</div>
							<div className="card-body text-center item-user">
								<div className="profile-pic">
									<div className="profile-pic-img">
										<span className="bg-success dots" data-bs-toggle="tooltip" data-bs-placement="top" title="online"></span>
										<img src="assets/images/faces/male/25.jpg" className="brround" alt="user"/>
									</div>
									<a href="userprofile.html" className="text-dark"><h4 className="mt-3 mb-0 font-weight-semibold">Robert McLean</h4></a>
								</div>
							</div>
							<aside className="doc-sidebar my-dash">
								<div className="app-sidebar__user clearfix">
									<ul className="side-menu">
										<li>
											<a className="side-menu__item" href="mydash.html"><i className="icon icon-user"></i><span className="side-menu__label ms-2">Edit Profile</span></a>
										</li>
										<li className="slide">
											<a className="side-menu__item" data-bs-toggle="slide" href="javascript:void(0)"><i className="icon icon-heart"></i><span className="side-menu__label ms-2">My Favorite</span><i className="angle fa fa-angle-right"></i></a>
											<ul className="slide-menu">
												<li><a className="slide-item" href="myfavorite.html"><i className="fa fa-angle-right me-2"></i>Favorite1</a></li>
									        	<li><a className="slide-item" href="javascript:void(0)"><i className="fa fa-angle-right me-2"></i>Favorite2</a></li>
											</ul>
										</li>
										<li className="slide">
											<a className="side-menu__item" data-bs-toggle="slide" href="javascript:void(0)"><i className="icon icon-diamond"></i><span className="side-menu__label ms-2">My Ads</span><i className="angle fa fa-angle-right"></i></a>
											<ul className="slide-menu">
												<li><a className="slide-item" href="myads.html"><i className="fa fa-angle-right me-2"></i>My-Ads 01</a></li>
												<li><a className="slide-item" href="javascript:void(0)"><i className="fa fa-angle-right me-2"></i>My-Ads 02</a></li>
											</ul>
										</li>
										<li className="slide">
											<a className="side-menu__item" data-bs-toggle="slide" href="javascript:void(0)"><i className="icon icon-folder-alt"></i><span className="side-menu__label ms-2">Managed Ads</span><i className="angle fa fa-angle-right"></i></a>
											<ul className="slide-menu">
												<li><a className="slide-item" href="manged.html"><i className="fa fa-angle-right me-2"></i>Managed Ads 01</a></li>
												<li><a className="slide-item" href="javascript:void(0)"><i className="fa fa-angle-right me-2"></i>Managed Ads 02</a></li>
												<li className="sub-slide">
													<a className="side-menu__item border-top-0 slide-item" href="javascript:void(0)" data-bs-toggle="sub-slide"><span className="side-menu__label"><i className="fa fa-angle-right me-2"></i>Managed Ads 03</span> <i className="sub-angle fa fa-angle-right"></i></a>
													<ul className="child-sub-menu ">
														<li><a className="slide-item" href="javascript:void(0)"><i className="fa fa-angle-right me-2 text-muted"></i>Managed Ads 04</a></li>
													</ul>
												</li>
											</ul>
										</li>
										<li>
											<a className="side-menu__item" href="payments.html"><i className="icon icon-credit-card"></i><span className="side-menu__label ms-2">Payments</span></a>
										</li>
										<li>
											<a className="side-menu__item" href="orders.html"><i className="icon icon-basket"></i><span className="side-menu__label ms-2">Orders</span></a>
										</li>
										<li>
											<a className="side-menu__item" href="tips.html"><i className="icon icon-game-controller"></i><span className="side-menu__label ms-2">Safety Tips</span></a>
										</li>
										<li>
											<a className="side-menu__item" href="settings.html"><i className="icon icon-settings"></i><span className="side-menu__label ms-2">Settings </span></a>
										</li>
										<li>
											<a className="side-menu__item" href="login.html"><i className="icon icon-power"></i><span className="side-menu__label ms-2">Logout</span></a>
										</li>
									</ul>
								</div>
							</aside>
						</div>
						<div className="card my-select">
							<div className="card-header">
								<h3 className="card-title">Search Ads</h3>
							</div>
							<div className="card-body">
								<div className="form-group">
									<input type="text" className="form-control" id="text" placeholder="What are you looking for?"/>
								</div>
								<div className="form-group">
									<select name="country" id="select-countries" className="form-control form-select select2-show-search">
										<option value="1" selected="">All Categories</option>
										<option value="2">RealEstate</option>
										<option value="3">Apartments</option>
										<option value="4">3BHK Flat</option>
										<option value="5">Homes</option>
										<option value="6">Luxury Rooms</option>
										<option value="7">Deluxe Houses</option>
										<option value="8">Duplex House</option>
										<option value="9">Luxury Rooms</option>
										<option value="10">Pets &amp; Flats</option>
										<option value="11">Apartments</option>
										<option value="12">Duplex Houses</option>
										<option value="13">3BHK Flatss</option>
										<option value="14">2BHK Flats</option>
										<option value="15">Modren Houses</option>
									</select>
								</div>
								<div className="">
									<a href="javascript:void(0);" className="btn  btn-primary">Search</a>
								</div>
							</div>
						</div>
						<div className="card mb-xl-0">
							<div className="card-header">
								<h3 className="card-title">Safety Tips For Buyers</h3>
							</div>
							<div className="card-body">
								<ul className="list-unstyled widget-spec  mb-0">
									<li className="">
										<i className="fa fa-check text-success" aria-hidden="true"></i> Meet Seller at public Place
									</li>
									<li className="">
										<i className="fa fa-check text-success" aria-hidden="true"></i> Check item before you buy
									</li>
									<li className="">
										<i className="fa fa-check text-success" aria-hidden="true"></i> Pay only after collecting item
									</li>
									<li className="ms-5 mb-0">
										<a href="tips.html"> View more..</a>
									</li>
								</ul>
							</div>
						</div>
					</div>
					<div className="col-xl-9 col-lg-12 col-md-12">
						<div className="card mb-0">
							<div className="card-header">
								<h3 className="card-title">Edit Profile</h3>
							</div>
							<div className="card-body">
								<div className="row">
									<div className="col-sm-6 col-md-6">
										<div className="form-group">
											<label className="form-label">First Name</label>
											<input type="text" className="form-control" placeholder="First Name"/>
										</div>
									</div>
									<div className="col-sm-6 col-md-6">
										<div className="form-group">
											<label className="form-label">Last Name</label>
											<input type="text" className="form-control" placeholder="Last Name"/>
										</div>
									</div>
									<div className="col-sm-6 col-md-6">
										<div className="form-group">
											<label className="form-label">Email address</label>
											<input type="email" className="form-control" placeholder="Email"/>
										</div>
									</div>
									<div className="col-sm-6 col-md-6">
										<div className="form-group">
											<label className="form-label">Phone Number</label>
											<input type="number" className="form-control" placeholder="Number"/>
										</div>
									</div>
									<div className="col-md-12">
										<div className="form-group">
											<label className="form-label">Address</label>
											<input type="text" className="form-control" placeholder="Home Address"/>
										</div>
									</div>
									<div className="col-sm-6 col-md-4">
										<div className="form-group">
											<label className="form-label">City</label>
											<input type="text" className="form-control" placeholder="City"/>
										</div>
									</div>
									<div className="col-sm-6 col-md-3">
										<div className="form-group">
											<label className="form-label">Postal Code</label>
											<input type="number" className="form-control" placeholder="ZIP Code"/>
										</div>
									</div>
									<div className="col-md-5">
										<div className="form-group">
											<label className="form-label">Country</label>
											<select className="form-control select2-show-search border-bottom-0 w-100 select2-show-search" data-placeholder="Select">
												<optgroup label="Categories">
													<option>--Select--</option>
													<option value="1">Germany</option>
													<option value="2">USA</option>
													<option value="3">Canada</option>
													<option value="4">Usa</option>
													<option value="5">Afghanistan</option>
													<option value="6">Albania</option>
													<option value="7">China</option>
													<option value="8">Denmark</option>
													<option value="9">Finland</option>
													<option value="10">India</option>
													<option value="11">Kiribati</option>
													<option value="12">Kuwait</option>
													<option value="13">Mexico</option>
													<option value="14">Pakistan</option>
												</optgroup>
											</select>
										</div>
									</div>
									<div className="col-sm-6 col-md-6">
										<div className="form-group">
											<label className="form-label">Facebook</label>
											<input type="text" className="form-control" placeholder="https://www.facebook.com/"/>
										</div>
									</div>
									<div className="col-sm-6 col-md-6">
										<div className="form-group">
											<label className="form-label">Google</label>
											<input type="text" className="form-control" placeholder="https://www.google.com/"/>
										</div>
									</div>
									<div className="col-sm-6 col-md-6">
										<div className="form-group">
											<label className="form-label">Twitter</label>
											<input type="text" className="form-control" placeholder="https://twitter.com/"/>
										</div>
									</div>
									<div className="col-sm-6 col-md-6">
										<div className="form-group">
											<label className="form-label">Pinterest</label>
											<input type="text" className="form-control" placeholder="https://in.pinterest.com/"/>
										</div>
									</div>
									<div className="col-md-12">
										<div className="form-group">
											<label className="form-label">About Me</label>
											<textarea rows="5" className="form-control" placeholder="Enter About your description"></textarea>
										</div>
									</div>
									<div className="col-md-12">
										<div className="form-group">
											<div className="form-file">
												<input type="file" className="form-control example-file-input-custom" name="example-file-input-custom"/>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="card-footer">
								<button type="submit" className="btn btn-primary">Updated Profile</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
		{/* <!--/User Dashboard--> */}

		{/* <!-- Newsletter--> */}
		<section className="sptb bg-white border-top">
			<div className="container">
				<div className="row">
					<div className="col-lg-7 col-xl-6 col-md-12">
						<div className="sub-newsletter">
							<h3 className="mb-2"><i className="fa fa-paper-plane-o me-2"></i> Subscribe To Our Newsletter</h3>
							<p className="mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor</p>
						</div>
					</div>
					<div className="col-lg-5 col-xl-6 col-md-12">
						<div className="input-group sub-input mt-1">
							<input type="text" className="form-control input-lg " placeholder="Enter your Email"/>
								<button type="button" className="btn btn-primary btn-lg br-tr-3  br-br-3">
									Subscribe
								</button>
						</div>
					</div>
				</div>
			</div>
		</section>
		{/* <!--/Newsletter--> */}

		{/* <!--Footer Section--> */}
		<Footer/>
		{/* <!--Footer Section--> */}
    </Fragment>
  )
}

export default MyDashboard