import React, { Fragment } from 'react'
import { NavLink } from 'react-router-dom'

const Header = ()=> {
  return (
    <Fragment>

        


        	{/* <!-- Duplex Houses Header --> */}
			<div className="sticky ">
				<div className="horizontal-header clearfix">
					<div className="container">
						<NavLink id="horizontal-navtoggle" to="#" className="animated-arrow"><span></span></NavLink>
						<span className="smllogo">
							<NavLink to="/">
								<img src="assets/images/brand/Oryx-Logo.png" to="#" className="mobile-light-logo" width="100" height="40" alt="javascript:void(0);"/>
								<img src="assets/images/brand/Oryx-Logo.png" to="#" className="mobile-dark-logo"   alt="javascript:void(0);"/>
							</NavLink>
						</span>
						<NavLink to="" className="callusbtn"><i className="fa fa-phone" aria-hidden="true"></i></NavLink>
					</div>
				</div>
			</div>
			{/* <!-- /Duplex Houses Header --> */}

			
              <div id="navbar_top" class="navbar navbar-expand-lg horizontal-main bg-dark-transparent shadow-lg p-3 mb-5 bg-light clearfix" >
				<div className="horizontal-mainwrapper container clearfix">
					<div className="desktoplogo">
						<NavLink to="/"><img src="assets/images/brand/Oryx-Logo.png"  width="120" height="60" alt=""/></NavLink>
					</div>
					
					{/* <!--Nav--> */}
					<nav className="horizontalMenu clearfix d-md-flex">
						<ul className="horizontalMenu-list">
						<li aria-haspopup="true"><NavLink to="/">Home</NavLink>
							</li>
							<li aria-haspopup="true"><NavLink to="/PropertiesPage" className="text-dark">Properties Page <span className="hmarrow"></span></NavLink></li>
							<li aria-haspopup="true"><NavLink to="/about" className="text-dark" >About Us </NavLink></li>
							<li aria-haspopup="true"><NavLink to="/Contact" className="text-dark"> Contact Us <span className="hmarrow"></span></NavLink></li>
							<li aria-haspopup="true" className="d-lg-none mt-5 pb-5 mt-lg-0">	
								<span className="text-dark"><NavLink className="btn btn-secondary" to="/Adpost"  >Post Property Ad</NavLink></span>
							</li>
						</ul>
						<ul className="mb-0">
							<li aria-haspopup="true" className="mt-3 d-none d-lg-block top-postbtn">
								<span><NavLink className="btn btn-secondary ad-post " to="/Adpost">Post Property Ad</NavLink></span>
							</li>
						</ul>
					</nav>
					{/* <!--Nav--> */}
				</div>
			</div>

    </Fragment>
  )
}

export default Header