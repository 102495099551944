import { Fragment } from 'react'
import { Link } from 'react-router-dom'

const Lproperties = () => {
  return (
    <Fragment>
        {/* <!--Latest Properties Ads--> */} 
		<section className="sptb bg-white">
				<div className="container">
					<div className="section-title center-block text-center">
						<h2>Latest Properties</h2>
						<p> Here are some latest properties or characteristics that people often look for in a residential property</p>
					</div>
					<div id="myCarousel1" className="owl-carousel owl-carousel-icons2">
						<div className="item">
							<div className="card mb-0">
								<div className="power-ribbon power-ribbon-hrefp-left text-warning"><span className="bg-warning"><i className="fa fa-bolt"></i></span></div>
								<div className="item-card2-img">
									<Link to="/$2BHKHouse"></Link>
									<img src="assets/images/products/products/f1.jpg" alt="img" className="cover-image" />
									<div className="tag-text">
										<span className="bg-danger tag-option">For Sale </span>
										<span className="bg-pink tag-option">Open</span>
									</div>
								</div>
								<div className="item-card2-icons">
									<Link to="/$2BHKHouse" className="item-card2-icons-l bg-primary"> <i className="fa fa-home"></i></Link>
									<Link to="javascript:void(0);" className="item-card2-icons-r bg-secondary"><i className="fa fa fa-heart-o"></i></Link>
								</div>
								<div className="card-body">
									<div className="item-card2">
										<div className="item-card2-text">
											<Link to="/$2BHKHouse" className="text-dark"><h4 className="javascript:void(0);">Deluxe Houses</h4></Link>
											<p className="mb-2"><i className="fa fa-map-marker text-danger me-1"></i> Preshrefn Street Wichita , USA </p>
											<h5 className="font-weight-bold mb-3">$89,005 <span className="fs-12  font-weight-normal">Per Month</span></h5>
										</div>
										<ul className="item-card2-list">
											<li><Link to="/$2BHKHouse"><i className="fa fa-arrows-alt text-muted me-1"></i> 256 Sqft</Link></li>
											<li><Link to="/$2BHKHouse" className="icons"><i className="fa fa-bed text-muted me-1"></i> 3 Beds</Link></li>
											<li><Link to="/$2BHKHouse" className="icons"><i className="fa fa-bath text-muted me-1"></i> 2 Bath</Link></li>
											<li><Link to="/$2BHKHouse" className="icons"><i className="fa fa-car text-muted me-1"></i> 1 Car</Link></li>
										</ul>
									</div>
								</div>
								<div className="card-footer">
									<div className="footerimg d-flex mt-0 mb-0">
										<div className="d-flex footerimg-l mb-0">
											<img src="assets/images/faces/male/18.jpg" alt="image" className="avatar brround  me-2" />
											<h5 className="time-title text-muted p-0 leading-normal my-auhref">Wendy Peake<i className="si si-check text-success fs-12 ms-1" data-bs-hrefggle="hrefoltip" data-bs-placement="hrefp" title="verified"></i></h5>
										</div>
										<div className="my-auhref footerimg-r ms-auhref">
											<small className="text-muted">1 day ago</small>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="item">
							<div className="card mb-0">
								<div className="power-ribbon power-ribbon-hrefp-left text-warning"><span className="bg-warning"><i className="fa fa-bolt"></i></span></div>
								<div className="item-card2-img">
									<Link to="/$2BHKHouse"></Link>
									<img src="assets/images/products/products/f2.jpg" alt="img" className="cover-image" />
									<div className="tag-text"><span className="bg-danger tag-option">For Rent </span></div>
								</div>
								<div className="item-card2-icons">
									<Link to="/$2BHKHouse" className="item-card2-icons-l bg-primary"> <i className="fa fa-home"></i></Link>
									<Link to="/$2BHKHouse" className="item-card2-icons-r bg-secondary"><i className="fa fa fa-heart-o"></i></Link>
								</div>
								<div className="card-body">
									<div className="item-card2">
										<div className="item-card2-text">
											<Link to="/$2BHKHouse" className="text-dark"><h4 className="javascript:void(0);">2BK Houses</h4></Link>
											<p className="mb-2"><i className="fa fa-map-marker text-danger me-1"></i> Preshrefn Street Wichita , USA </p>
											<h5 className="font-weight-bold mb-3">$12,890 <span className="fs-12  font-weight-normal">Per Month</span></h5>
										</div>
										<ul className="item-card2-list">
											<li><Link to="/$2BHKHouse"><i className="fa fa-arrows-alt text-muted me-1"></i> 150 Sqft</Link></li>
											<li><Link to="/$2BHKHouse" className="icons"><i className="fa fa-bed text-muted me-1"></i> 2 Beds</Link></li>
											<li><Link to="/$2BHKHouse" className="icons"><i className="fa fa-bath text-muted me-1"></i> 3 Bath</Link></li>
											<li><Link to="/$2BHKHouse" className="icons"><i className="fa fa-car text-muted me-1"></i> 1 Car</Link></li>
										</ul>
									</div>
								</div>
								<div className="card-footer">
									<div className="footerimg d-flex mt-0 mb-0">
										<div className="d-flex footerimg-l mb-0">
											<img src="assets/images/faces/female/12.jpg" alt="image" className="avatar brround  me-2" />
											<h5 className="time-title text-muted p-0 leading-normal my-auhref">Ryan Lyman<i className="si si-check text-success fs-12 ms-1" data-bs-hrefggle="hrefoltip" data-bs-placement="hrefp" title="verified"></i></h5>
										</div>
										<div className="my-auhref footerimg-r ms-auhref">
											<small className="text-muted">55 mins ago</small>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="item">
							<div className="card mb-0">
								<div className="item-card2-img">
									<Link to="/$2BHKHouse"></Link>
									<img src="assets/images/products/products/f3.jpg" alt="img" className="cover-image" />
									<div className="tag-text">
										<span className="bg-danger tag-option">For Rent </span>
										<span className="bg-pink tag-option">Hot</span>
									</div>
								</div>
								<div className="item-card2-icons">
									<Link to="/$2BHKHouse" className="item-card2-icons-l bg-primary"> <i className="fa fa-home"></i></Link>
									<Link to="/$2BHKHouse" className="item-card2-icons-r bg-secondary"><i className="fa fa fa-heart-o"></i></Link>
								</div>
								<div className="card-body">
									<div className="item-card2">
										<div className="item-card2-text">
											<Link to="/$2BHKHouse" className="text-dark"><h4 className="javascript:void(0);">Office Rooms</h4></Link>
											<p className="mb-2"><i className="fa fa-map-marker text-danger me-1"></i> Preshrefn Street Wichita , USA </p>
											<h5 className="font-weight-bold mb-3">$25,784 <span className="fs-12  font-weight-normal">Per Month</span></h5>
										</div>
										<ul className="item-card2-list">
											<li><Link to="/$2BHKHouse"><i className="fa fa-arrows-alt text-muted me-1"></i> 256 Sqft</Link></li>
											<li><Link to="/$2BHKHouse" className="icons"><i className="fa fa-bed text-muted me-1"></i> 8 Beds</Link></li>
											<li><Link to="/$2BHKHouse" className="icons"><i className="fa fa-bath text-muted me-1"></i> 4 Bath</Link></li>
											<li><Link to="/$2BHKHouse" className="icons"><i className="fa fa-car text-muted me-1"></i> 4 Car</Link></li>
										</ul>
									</div>
								</div>
								<div className="card-footer">
									<div className="footerimg d-flex mt-0 mb-0">
										<div className="d-flex footerimg-l mb-0">
											<img src="assets/images/faces/male/8.jpg" alt="image" className="avatar brround  me-2" />
											<h5 className="time-title text-muted p-0 leading-normal my-auhref">Joan Hunter<i className="si si-check text-success fs-12 ms-1" data-bs-hrefggle="hrefoltip" data-bs-placement="hrefp" title="verified"></i></h5>
										</div>
										<div className="my-auhref footerimg-r ms-auhref">
											<small className="text-muted">2 day ago</small>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="item">
							<div className="card mb-0">
								<div className="power-ribbon power-ribbon-hrefp-left text-warning"><span className="bg-warning"><i className="fa fa-bolt"></i></span></div>
								<div className="item-card2-img">
									<Link to="/$2BHKHouse"></Link>
									<img src="assets/images/products/products/f4.jpg" alt="img" className="cover-image" />
									<div className="tag-text"><span className="bg-danger tag-option">For Sale </span></div>
								</div>
								<div className="item-card2-icons">
									<Link to="/$2BHKHouse" className="item-card2-icons-l bg-primary"> <i className="fa fa-home"></i></Link>
									<Link to="/$2BHKHouse" className="item-card2-icons-r bg-secondary"><i className="fa fa fa-heart-o"></i></Link>
								</div>
								<div className="card-body">
									<div className="item-card2">
										<div className="item-card2-text">
											<Link to="/$2BHKHouse" className="text-dark"><h4 className="javascript:void(0);">Apartments</h4></Link>
											<p className="mb-2"><i className="fa fa-map-marker text-danger me-1"></i> Preshrefn Street Wichita , USA </p>
											<h5 className="font-weight-bold mb-3">$89,005 <span className="fs-12  font-weight-normal">Per Month</span></h5>
										</div>
										<ul className="item-card2-list">
											<li><Link to="/$2BHKHouse"><i className="fa fa-arrows-alt text-muted me-1"></i> 700 Sqft</Link></li>
											<li><Link to="/$2BHKHouse" className="icons"><i className="fa fa-bed text-muted me-1"></i> 20 Beds</Link></li>
											<li><Link to="/$2BHKHouse" className="icons"><i className="fa fa-bath text-muted me-1"></i> 10 Bath</Link></li>
											<li><Link to="/$2BHKHouse" className="icons"><i className="fa fa-car text-muted me-1"></i> 10 Car</Link></li>
										</ul>
									</div>
								</div>
								<div className="card-footer">
									<div className="footerimg d-flex mt-0 mb-0">
										<div className="d-flex footerimg-l mb-0">
											<img src="assets/images/faces/female/19.jpg" alt="image" className="avatar brround  me-2" />
											<h5 className="time-title text-muted p-0 leading-normal my-auhref">Elizabeth<i className="si si-check text-success fs-12 ms-1" data-bs-hrefggle="hrefoltip" data-bs-placement="hrefp" title="verified"></i></h5>
										</div>
										<div className="my-auhref footerimg-r ms-auhref">
											<small className="text-muted">50 mins ago</small>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="item sold-out">
							<div className="ribbon sold-ribbon ribbon-hrefp-left text-danger"><span className="bg-danger">Sold Out</span></div>
							<div className="card mb-0">
								<div className="power-ribbon power-ribbon-hrefp-left text-warning"><span className="bg-warning"><i className="fa fa-bolt"></i></span></div>
								<div className="item-card2-img">
									<Link to="/$2BHKHouse"></Link>
									<img src="assets/images/products/products/f3.jpg" alt="img" className="cover-image" />
									<div className="tag-text">
										<span className="bg-danger tag-option">For Sale </span>
										<span className="bg-pink tag-option">New</span>
									</div>
								</div>
								<div className="item-card2-icons">
									<Link to="/$2BHKHouse" className="item-card2-icons-l bg-primary"> <i className="fa fa-home"></i></Link>
									<Link to="/$2BHKHouse" className="item-card2-icons-r bg-secondary"><i className="fa fa fa-heart-o"></i></Link>
								</div>
								<div className="card-body">
									<div className="item-card2">
										<div className="item-card2-text">
											<Link to="/$2BHKHouse" className="text-dark"><h4 className="javascript:void(0);">Duplex House</h4></Link>
											<p className="mb-2"><i className="fa fa-map-marker text-danger me-1"></i> Preshrefn Street Wichita , USA </p>
											<h5 className="font-weight-bold mb-3">$23,789 <span className="fs-12  font-weight-normal">Per Month</span></h5>
										</div>
										<ul className="item-card2-list">
											<li><Link to="/$2BHKHouse"><i className="fa fa-arrows-alt text-muted me-1"></i> 300 Sqft</Link></li>
											<li><Link to="/$2BHKHouse" className="icons"><i className="fa fa-bed text-muted me-1"></i> 4 Beds</Link></li>
											<li><Link to="/$2BHKHouse" className="icons"><i className="fa fa-bath text-muted me-1"></i> 3 Bath</Link></li>
											<li><Link to="/$2BHKHouse" className="icons"><i className="fa fa-car text-muted me-1"></i> 1 Car</Link></li>
										</ul>
									</div>
								</div>
								<div className="card-footer">
									<div className="footerimg d-flex mt-0 mb-0">
										<div className="d-flex footerimg-l mb-0">
											<img src="assets/images/faces/female/18.jpg" alt="image" className="avatar brround  me-2" />
											<h5 className="time-title text-muted p-0 leading-normal my-auhref">Boris Nash<i className="si si-check text-success fs-12 ms-1" data-bs-hrefggle="hrefoltip" data-bs-placement="hrefp" title="verified"></i></h5>
										</div>
										<div className="my-auhref footerimg-r ms-auhref">
											<small className="text-muted">12 mins ago</small>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			 {/* <!--Latest Properties Ads--> */}
    </Fragment>
  )
}

export default Lproperties