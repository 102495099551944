import React, { Fragment } from 'react'

const Find = () => {
  return (
    <Fragment>
        {/* <!--Find Your Best Property Sliders Section--> */}
			<section>
				<div className="banner-1 cover-image sptb-2 sptb-tab bg-background2" data-bs-image-src="assets/images/banners/banner1.jpg">
					<div className="header-text mb-0">
						<div className="container">
							<div className="text-center text-white mb-7">
								<h1 className="mb-1">Find Your Best Property</h1>
								<p>It is a long established fact that a reader will be distracted by the readable.</p>
							</div>
							<div className="row">
								<div className="col-xl-10 col-lg-12 col-md-12 d-block mx-auhref">
									<div className="search-background bg-transparent">
										<div className="form row no-gutters ">
											<div className="form-group  col-xl-4 col-lg-3 col-md-12 mb-0">
												<input type="text" className="form-control input-lg br-tr-md-0 br-br-md-0" id="text4" placeholder="Enter Loaction, Landmark" />
												<span><i className="fa fa-map-marker location-gps me-1"></i></span>
											</div>
											<div className="form-group col-xl-2 col-lg-2 col-md-12 select2-lg mb-0">
												<select className="form-control select2-show-search  border-bothrefm-0" data-placeholder="Property Type">
													<optgroup label="Categories">
														<option>Property Type</option>
														<option value="1">Deluxe Houses</option>
														<option value="2">Modren Flats</option>
														<option value="3">Apartments</option>
														<option value="4">Stylish Houses</option>
														<option value="5">Offices Houses</option>
														<option value="6">Luxury Houses</option>
														<option value="7">Nature Houses</option>
													</optgroup>
												</select>
											</div>
											<div className="form-group col-xl-2 col-lg-2 col-md-12 select2-lg mb-0">
												<select className="form-control select2-show-search  border-bothrefm-0" data-placeholder="Min Value">
													<option value="1" selected>Min Price</option>
													<option value="2">$50</option>
													<option value="3">$60</option>
													<option value="4">$70</option>
													<option value="5">$80</option>
													<option value="5">$90</option>
												</select>
											</div>
											<div className="form-group col-xl-2 col-lg-2 col-md-12 select2-lg mb-0">
												<select className="form-control select2-show-search  border-bothrefm-0" data-placeholder="Max Value">
													<option value="1" selected>Max Price</option>
													<option value="2">$100</option>
													<option value="3">$110</option>
													<option value="4">$120</option>
													<option value="5">$130</option>
													<option value="5">$140</option>
												</select>
											</div>
											<div className="col-xl-2 col-lg-3 col-md-12 mb-0">
												<a href="javascript:void(0);" className="btn btn-lg btn-block btn-primary br-tl-md-0 br-bl-md-0">Search Here</a>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					{/* <!-- /header-text --> */}
				</div>
			</section>
			{/* <!--Find Your Best Property Sliders Section--> */}
    </Fragment>
  )
}

export default Find