import React, { Fragment } from 'react'

const RecentPost = () => {
    return (
        <Fragment>
            {/* <!-- Recent Posts --> * */}
            <section className="sptb bg-white">
                <div className="container">
                    <div className="section-title center-block text-center">
                        <h2>Recent Posts</h2>
                        <p>Here are some Recent Post properties</p>
                    </div>
                    <div id="defaultCarousel" className="owl-carousel Card-owlcarousel owl-carousel-icons">
                        <div className="item">
                            <div className="card mb-0">
                                <div className="item7-card-img">
                                    <a href="/$2BHKHouse"></a>
                                    <img src="assets/images/products/products/f1.jpg" alt="img" className="cover-image" />
                                    <div className="item7-card-text">
                                        <span className="badge badge-info">Farm House</span>
                                    </div>
                                </div>
                                <div className="card-body p-4">
                                    <div className="item7-card-desc d-flex mb-2">
                                        <a href="javascript:void(0);" className="text-muted"><i className="fa fa-calendar-o text-muted me-2"></i>July-03-2019</a>
                                        <div className="ms-auhref">
                                            <a href="javascript:void(0);" className="text-muted"><i className="fa fa-comment-o text-muted me-2"></i>4 Comments</a>
                                        </div>
                                    </div>
                                    <a href="/$2BHKHouse" className="text-dark"><h4 className="fs-20">Luxury Flat For Rent</h4></a>
                                    <p>Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip commodo consequat </p>
                                    <div className="d-flex align-items-center pt-2 mt-auhref">
                                        <img src="assets/images/faces/male/5.jpg" className="avatar brround avatar-md me-3" alt="avatar-img" />
                                        <div>
                                            <a href="profile.html" className="text-default">Joanne Nash</a>
                                            <small className="d-block text-muted">1 day ago</small>
                                        </div>
                                        <div className="ms-auhref text-muted">
                                            <a href="javascript:void(0)" className="icon d-none d-md-inline-block ms-3"><i className="fe fe-heart me-1"></i></a>
                                            <a href="javascript:void(0)" className="icon d-none d-md-inline-block ms-3"><i className="fa fa-thumbs-o-up"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="card mb-0">
                                <div className="item7-card-img">
                                    <a href="/$2BHKHouse"></a>
                                    <img src="assets/images/products/products/f2.jpg" alt="img" className="cover-image" />
                                    <div className="item7-card-text">
                                        <span className="badge badge-primary">Luxury Room</span>
                                    </div>
                                </div>
                                <div className="card-body p-4">
                                    <div className="item7-card-desc d-flex mb-2">
                                        <a href="javascript:void(0);" className="text-muted"><i className="fa fa-calendar-o text-muted me-2"></i>June-03-2019</a>
                                        <div className="ms-auhref">
                                            <a href="javascript:void(0);" className="text-muted"><i className="fa fa-comment-o text-muted me-2"></i>2 Comments</a>
                                        </div>
                                    </div>
                                    <a href="/$2BHKHouse" className="text-dark"><h4 className="fs-20">Deluxe Flat For Sale</h4></a>
                                    <p>Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip commodo consequat </p>
                                    <div className="d-flex align-items-center pt-2 mt-auhref">
                                        <img src="assets/images/faces/male/7.jpg" className="avatar brround avatar-md me-3" alt="avatar-img" />
                                        <div>
                                            <a href="profile.html" className="text-default">Tanner Mallari</a>
                                            <small className="d-block text-muted">2 days ago</small>
                                        </div>
                                        <div className="ms-auhref text-muted">
                                            <a href="javascript:void(0)" className="icon d-none d-md-inline-block ms-3"><i className="fe fe-heart me-1"></i></a>
                                            <a href="javascript:void(0)" className="icon d-none d-md-inline-block ms-3"><i className="fa fa-thumbs-o-up"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="card mb-0">
                                <div className="item7-card-img">
                                    <a href="/$2BHKHouse"></a>
                                    <img src="assets/images/products/products/f3.jpg" alt="img" className="cover-image" />
                                    <div className="item7-card-text">
                                        <span className="badge badge-success">2BHK Flat</span>
                                    </div>
                                </div>
                                <div className="card-body p-4">
                                    <div className="item7-card-desc d-flex mb-2">
                                        <a href="javascript:void(0);" className="text-muted"><i className="fa fa-calendar-o text-muted me-2"></i>June-19-2019</a>
                                        <div className="ms-auhref">
                                            <a href="javascript:void(0);" className="text-muted"><i className="fa fa-comment-o text-muted me-2"></i>8 Comments</a>
                                        </div>
                                    </div>
                                    <a href="/$2BHKHouse" className="text-dark"><h4 className="fs-20">Luxury Home  For Sale</h4></a>
                                    <p>Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip commodo consequat </p>
                                    <div className="d-flex align-items-center pt-2 mt-auhref">
                                        <img src="assets/images/faces/female/15.jpg" className="avatar brround avatar-md me-3" alt="avatar-img" />
                                        <div>
                                            <a href="profile.html" className="text-default">Aracely Bashore</a>
                                            <small className="d-block text-muted">5 days ago</small>
                                        </div>
                                        <div className="ms-auhref text-muted">
                                            <a href="javascript:void(0)" className="icon d-none d-md-inline-block ms-3"><i className="fe fe-heart me-1"></i></a>
                                            <a href="javascript:void(0)" className="icon d-none d-md-inline-block ms-3"><i className="fa fa-thumbs-o-up"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="card mb-0">
                                <div className="item7-card-img">
                                    <a href="/$2BHKHouse"></a>
                                    <img src="assets/images/products/products/f4.jpg" alt="img" className="cover-image" />
                                    <div className="item7-card-text">
                                        <span className="badge badge-primary">Duplex House</span>
                                    </div>
                                </div>
                                <div className="card-body p-4">
                                    <div className="item7-card-desc d-flex mb-2">
                                        <a href="javascript:void(0);" className="text-muted"><i className="fa fa-calendar-o text-muted me-2"></i>June-03-2019</a>
                                        <div className="ms-auhref">
                                            <a href="javascript:void(0);" className="text-muted"><i className="fa fa-comment-o text-muted me-2"></i>4 Comments</a>
                                        </div>
                                    </div>
                                    <a href="/$2BHKHouse" className="text-dark"><h4 className="font-weight-semibold">Luxury Flat For Rent</h4></a>
                                    <p>Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip commodo consequat </p>
                                    <div className="d-flex align-items-center pt-2 mt-auhref">
                                        <img src="assets/images/faces/male/15.jpg" className="avatar brround avatar-md me-3" alt="avatar-img" />
                                        <div>
                                            <a href="profile.html" className="text-default">Royal Hamblin</a>
                                            <small className="d-block text-muted">10 days ago</small>
                                        </div>
                                        <div className="ms-auhref text-muted">
                                            <a href="javascript:void(0)" className="icon d-none d-md-inline-block ms-3"><i className="fe fe-heart me-1"></i></a>
                                            <a href="javascript:void(0)" className="icon d-none d-md-inline-block ms-3"><i className="fa fa-thumbs-o-up"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="card mb-0">
                                <div className="item7-card-img">
                                    <a href="/$2BHKHouse"></a>
                                    <img src="assets/images/products/products/f6.jpg" alt="img" className="cover-image" />
                                    <div className="item7-card-text">
                                        <span className="badge badge-pink">Budget House</span>
                                    </div>
                                </div>
                                <div className="card-body p-4">
                                    <div className="item7-card-desc d-flex mb-2">
                                        <a href="javascript:void(0);" className="text-muted"><i className="fa fa-calendar-o text-muted me-2"></i>May-28-2019</a>
                                        <div className="ms-auhref">
                                            <a href="javascript:void(0);" className="text-muted"><i className="fa fa-comment-o text-muted me-2"></i>2 Comments</a>
                                        </div>
                                    </div>
                                    <a href="/$2BHKHouse" className="text-dark"><h4 className="font-weight-semibold">Home  For Sale</h4></a>
                                    <p>Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip commodo consequat </p>
                                    <div className="d-flex align-items-center pt-2 mt-auhref">
                                        <img src="assets/images/faces/female/5.jpg" className="avatar brround avatar-md me-3" alt="avatar-img" />
                                        <div>
                                            <a href="profile.html" className="text-default">Rosita Chatmon</a>
                                            <small className="d-block text-muted">10 days ago</small>
                                        </div>
                                        <div className="ms-auhref text-muted">
                                            <a href="javascript:void(0)" className="icon d-none d-md-inline-block ms-3"><i className="fe fe-heart me-1"></i></a>
                                            <a href="javascript:void(0)" className="icon d-none d-md-inline-block ms-3"><i className="fa fa-thumbs-o-up"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="card mb-0">
                                <div className="item7-card-img">
                                    <a href="/$2BHKHouse"></a>
                                    <img src="assets/images/products/products/f6.jpg" alt="img" className="cover-image" />
                                    <div className="item7-card-text">
                                        <span className="badge badge-success">3BHK Flats</span>
                                    </div>
                                </div>
                                <div className="card-body p-4">
                                    <div className="item7-card-desc d-flex mb-2">
                                        <a href="javascript:void(0);" className="text-muted"><i className="fa fa-calendar-o text-muted me-2"></i>May-19-2019</a>
                                        <div className="ms-auhref">
                                            <a href="javascript:void(0);" className="text-muted"><i className="fa fa-comment-o text-muted me-2"></i>8 Comments</a>
                                        </div>
                                    </div>
                                    <a href="/$2BHKHouse" className="text-dark"><h4 className="font-weight-semibold">Luxury Home  For Sale</h4></a>
                                    <p>Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip commodo consequat </p>
                                    <div className="d-flex align-items-center pt-2 mt-auhref">
                                        <img src="assets/images/faces/male/6.jpg" className="avatar brround avatar-md me-3" alt="avatar-img" />
                                        <div>
                                            <a href="profile.html" className="text-default">Loyd Nolf</a>
                                            <small className="d-block text-muted">15 days ago</small>
                                        </div>
                                        <div className="ms-auhref text-muted">
                                            <a href="javascript:void(0)" className="icon d-none d-md-inline-block ms-3"><i className="fe fe-heart me-1"></i></a>
                                            <a href="javascript:void(0)" className="icon d-none d-md-inline-block ms-3"><i className="fa fa-thumbs-o-up"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
             {/* <!--Recent Posts --> */}
    </Fragment>
  )
}

export default RecentPost