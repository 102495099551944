import React, { Fragment } from 'react'
import HeaderProperties from './Component/HeaderProperties'
import Categories from './Component/Categories'
import Lproperties from './Component/Lproperties'
import Statistics from './Component/Statistics'
import Bproperties from './Component/Bproperties'
import Fproperties from './Component/Fproperties'
import Toplisting from './Component/Top-listing'
import Download from './Component/Download'
import RecentPost from './Component/Recent Post'
import Footer from './Component/Footer'
import AvailableProperties from './Component/AvailableProperties'


function PropertiesPage() {
  return (
    <Fragment>
       <HeaderProperties/>

       <AvailableProperties/>


        {/* <!--Categories Menu-->

		<!--Categories--> */}
			<Categories />
			{/* <!--/Categories-->

		<!--LatestProperties Ads--> */}
			<Lproperties/>
		{/* <!--Latest Properties Ads-->

		<!--Featured Properties Ads--> */}
			<Fproperties/>
			{/* <!--/Featured Properties Ads-->

		<!--Statistics--> */}
			<Statistics/>
			{/* <!--/Statistics-->

		<!--Property--> */}
			<Bproperties/>
			{/* <!--/Property-->

		<!--Testimonials--> */}
			{/* <Testimonials/> */}
			{/* <!--/Testimonials--> */}

		{/* <!--Top Lisiting Places--> */}
			<Toplisting/>
			{/* <!--Top Lisiting Places--> */}

		{/* <!--Download --> */}
			<Download/>
			{/* <!--Download -->

		<!-- Recent Posts --> */}
			<RecentPost/>
			{/* <!--Recent Posts -->

		<!--Footer Section--> */}


        <Footer/>





    </Fragment>
  )
}

export default PropertiesPage