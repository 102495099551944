import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import Footer from './Component/Footer'
import Abpostheader from './Component/Abpostheader'

function Adpost() {
  return (
    <Fragment>
        {/* <!--Sliders Section--> */}

         <Abpostheader/>
		 
		<section>
			<div className="bannerimg cover-image bg-background3" data-bs-image-src="../assets/images/banners/banner2.jpg">
				<div className="header-text mb-0">
					<div className="container">
						<div className="text-center text-white">
							<h1>Ad Post</h1>
							<ol className="breadcrumb text-center">
								<li className="breadcrumb-item"><Link to="/">Home</Link></li>
								<li className="breadcrumb-item"><Link to="javascript:void(0);">Pages</Link></li>
								<li className="breadcrumb-item active text-white" aria-current="page">Ad Post</li>
							</ol>
						</div>
					</div>
				</div>
			</div>
		</section>
		{/* <!--/Sliders Section--> */}

		{/* <!--Add posts-section--> */}
		<section className="sptb">
			<div className="container">
				<div className="row ">
					<div className="col-lg-8 col-md-12 col-md-12">
						<div className="card ">
							<div className="card-header ">
								<h3 className="card-title">Add Post</h3>
							</div>
							<div className="card-body">
								<div className="form-group">
									<label className="form-label text-dark">Ad Title</label>
									<input type="text" className="form-control" placeholder=""/>
								</div>
								<div className="form-group">
									<label className="form-label text-dark">Category</label>
									<select className="form-control  select2 form-select">
										<option value="0">Select Option</option>
										<option value="1">RealEstate</option>
										<option value="2">Apartments</option>
										<option value="3">Offices & Fitness</option>
										<option value="4">2BHK Flats</option>
										<option value="5">Apartments</option>
										<option value="6">Luxury Rooms</option>
										<option value="7">Homes</option>
										<option value="8">Luxury Homes</option>
										<option value="9">Modren Houses</option>
										<option value="10">Home & Furinture</option>
										<option value="11"> Homes</option>
										<option value="12">Duplex House</option>
										<option value="13">Luxury Rooms</option>
										<option value="14">3BHK Flatss</option>
									</select>
								</div>
								<div className="form-group">
									<label className="form-label text-dark">Type Of Ad</label>
									<div className="d-md-flex ad-post-details">
										<label className="custom-control custom-radio mb-2 me-4">
											<input type="radio" className="custom-control-input" name="radios" value="option1" checked=""/>
											<span className="custom-control-label">I Want to Sell</span>
										</label>
										<label className="custom-control custom-radio  mb-2 me-4">
											<input type="radio" className="custom-control-input" name="radios" value="option2"/>
											<span className="custom-control-label">I Want to Buy</span>
										</label>
										<label className="custom-control custom-radio  mb-2">
											<input type="radio" className="custom-control-input" name="radios" value="option3"/>
											<span className="custom-control-label">I Want to Rent</span>
										</label>
									</div>
								</div>
								<div className="form-group">
									<label className="form-label text-dark">Who We Are</label>
									<div className="d-md-flex ad-post-details">
										<label className="custom-control custom-radio mb-2 me-4">
											<input type="radio" className="custom-control-input" name="radios2" value="option4" checked=""/>
											<span className="custom-control-label">Owner </span>
										</label>
										<label className="custom-control custom-radio  mb-2 me-4">
											<input type="radio" className="custom-control-input" name="radios2" value="option5"/>
											<span className="custom-control-label">Agent</span>
										</label>
										<label className="custom-control custom-radio  mb-2">
											<input type="radio" className="custom-control-input" name="radios2" value="option6"/>
											<span className="custom-control-label">Builder</span>
										</label>
									</div>
								</div>
								<div className="form-group">
									<label className="form-label text-dark">Description</label>
									<textarea className="form-control" name="example-textarea-input" rows="6" placeholder="text here.."></textarea>
								</div>
								<div className="p-2 border mb-4 form-group">
									<form>
										<label className="form-label text-dark">Upload Images</label>
										<input id="demo" type="file" name="files" accept=".jpg, .png, image/jpeg, image/png, html, zip, css,js" multiple/>
									</form>
								</div>
								<div className="row">
									<div className="col-sm-6 col-md-6">
										<div className="form-group">
											<label className="form-label">Name</label>
											<input type="text" className="form-control" placeholder="Name"/>
										</div>
									</div>
									<div className="col-sm-6 col-md-6">
										<div className="form-group">
											<label className="form-label">Email</label>
											<input type="email" className="form-control" placeholder="Email Address"/>
										</div>
									</div>
									<div className="col-sm-6 col-md-6">
										<div className="form-group mb-0">
											<label className="form-label">Phone Number</label>
											<input type="text" className="form-control" placeholder="Number"/>
										</div>
									</div>
									<div className="col-sm-6 col-md-6">
										<div className="form-group mb-0">
											<label className="form-label">Address</label>
											<input type="text" className="form-control" placeholder="Address"/>
										</div>
									</div>
								</div>
								<div className="border p-3 mt-4">
									<div className="control-group form-group mb-0">
										<label className="form-label text-dark">Ad Post Package</label>
										<div className="d-md-flex ad-post-details">
											<label className="custom-control custom-radio mb-2 me-4">
												<input type="radio" className="custom-control-input" name="radios1" value="option7" checked=""/>
												<span className="custom-control-label">30 Days Free</span>
											</label>
											<label className="custom-control custom-radio  mb-2 me-4">
												<input type="radio" className="custom-control-input" name="radios1" value="option8" />
												<span className="custom-control-label">60 days / <span className="font-weight-bold">$20</span></span>
											</label>
											<label className="custom-control custom-radio  mb-2 me-4">
												<input type="radio" className="custom-control-input" name="radios1" value="option9"/>
												<span className="custom-control-label">6months /<span className="font-weight-bold">$50</span></span>
											</label>
											<label className="custom-control custom-radio  mb-2">
												<input type="radio" className="custom-control-input" name="radios1" value="option10" />
												<span className="custom-control-label">1 year / <span className="font-weight-bold">$80</span></span>
											</label>
										</div>
									</div>
								</div>
							</div>
							<div className="card-footer ">
								<Link to="javascript:void(0);" className="btn btn-success">Submit Now</Link>
							</div>
						</div>
					</div>
					<div className="col-lg-4 col-md-12">
						<div className="card">
							<div className="card-header">
								<h3 className="card-title">Benefits Of Premium Ad</h3>
							</div>
							<div className="card-body pb-2">
								<ul className="list-unstyled widget-spec vertical-scroll mb-0">
									<li>
										<i className="fa fa-check text-success" aria-hidden="true"></i>Premium Ads Active
									</li>
									<li>
										<i className="fa fa-check text-success" aria-hidden="true"></i>Premium ads are displayed on top
									</li>
									<li>
										<i className="fa fa-check text-success" aria-hidden="true"></i>Premium ads will be Show in Google results
									</li>
									<li>
										<i className="fa fa-check text-success" aria-hidden="true"></i>Premium Ads Active
									</li>
									<li>
										<i className="fa fa-check text-success" aria-hidden="true"></i>Premium ads are displayed on top
									</li>
									<li>
										<i className="fa fa-check text-success" aria-hidden="true"></i>Premium ads will be Show in Google results
									</li>
									<li>
										<i className="fa fa-check text-success" aria-hidden="true"></i>Premium Ads Active
									</li>
									<li>
										<i className="fa fa-check text-success" aria-hidden="true"></i>Premium ads are displayed on top
									</li>
									<li>
										<i className="fa fa-check text-success" aria-hidden="true"></i>Premium ads will be Show in Google results
									</li>
									<li>
										<i className="fa fa-check text-success" aria-hidden="true"></i>Premium Ads Active
									</li>
									<li>
										<i className="fa fa-check text-success" aria-hidden="true"></i>Premium ads are displayed on top
									</li>
									<li>
										<i className="fa fa-check text-success" aria-hidden="true"></i>Premium ads will be Show in Google results
									</li>
									<li>
										<i className="fa fa-check text-success" aria-hidden="true"></i>Premium Ads Active
									</li>
									<li>
										<i className="fa fa-check text-success" aria-hidden="true"></i>Premium ads are displayed on top
									</li>
									<li>
										<i className="fa fa-check text-success" aria-hidden="true"></i>Premium ads will be Show in Google results
									</li>
								</ul>
							</div>
						</div>
						<div className="card">
							<div className="card-header">
								<h3 className="card-title">Terms And Conditions</h3>
							</div>
							<div className="card-body">
								<ul className="list-unstyled widget-spec  mb-0">
									<li>
										<i className="fa fa-check text-success" aria-hidden="true"></i>Money Not Refundable
									</li>
									<li>
										<i className="fa fa-check text-success" aria-hidden="true"></i>You can renew your Premium ad after experted.
									</li>
									<li>
										<i className="fa fa-check text-success" aria-hidden="true"></i>Premium ads are active for depend on package.
									</li>
									<li className="ms-5 mb-0">
										<Link to="tips.html"> View more..</Link>
									</li>
								</ul>
							</div>
						</div>
						<div className="card mb-0">
							<div className="card-header">
								<h3 className="card-title">Safety Tips For Buyers</h3>
							</div>
							<div className="card-body">
								<ul className="list-unstyled widget-spec  mb-0">
									<li>
										<i className="fa fa-check text-success" aria-hidden="true"></i> Meet Seller at public Place
									</li>
									<li>
										<i className="fa fa-check text-success" aria-hidden="true"></i> Check item before you buy
									</li>
									<li>
										<i className="fa fa-check text-success" aria-hidden="true"></i> Pay only after collecting item
									</li>
									<li className="ms-5 mb-0">
										<Link to="tips.html"> View more..</Link>
									</li>
								</ul>
							</div>
						</div>
					</div>

					<div className="col-xl-8">
						<div className="card mb-xl-0">
							<div className="card-header">
								<h3 className="card-title">Payments</h3>
							</div>
							<div className="card-body">
								<div className="tab-content card-body border mb-0 b-0">
									<div className="panel panel-primary">
										<div className=" tab-menu-heading border-0 px-0">
											<div className="tabs-menu1 ">
												{/* <!-- Tabs --> */}
												<ul className="nav panel-tabs">
													<li><Link to="#tab5" className="active" data-bs-toggle="tab">Credit/ Debit Card</Link></li>
													<li><Link to="#tab6" data-bs-toggle="tab">Paypal</Link></li>
													<li><Link to="#tab7" data-bs-toggle="tab">Net Banking</Link></li>
													<li><Link to="#tab8" data-bs-toggle="tab">Gift Voucher</Link></li>
												</ul>
											</div>
										</div>
										<div className="panel-body tabs-menu-body ps-0 pe-0 border-0">
											<div className="tab-content">
												<div className="tab-pane active " id="tab5">
													<div className="form-group">
														<label className="form-label" >CardHolder Name</label>
														<input type="text" className="form-control" id="name1" placeholder="First Name"/>
													</div>
													<div className="form-group">
														<label className="form-label">Card number</label>
														<div className="input-group">
															<input type="text" className="form-control" placeholder="Search for..."/>
																<button className="btn btn-info" type="button"><i className="fa fa-cc-visa"></i> &nbsp; <i className="fa fa-cc-amex"></i> &nbsp;
																<i className="fa fa-cc-mastercard"></i></button>
														</div>
													</div>
													<div className="row">
														<div className="col-sm-8">
															<div className="form-group mb-sm-0">
																<label className="form-label">Expiration</label>
																<div className="input-group">
																	<input type="number" className="form-control border-end-0" placeholder="MM" name="expiremonth"/>
																	<input type="number" className="form-control" placeholder="YY" name="expireyear"/>
																</div>
															</div>
														</div>
														<div className="col-sm-4 ">
															<div className="form-group mb-0">
																<label className="form-label">CVV <i className="fa fa-question-circle" data-bs-toggle="tooltip" data-bs-placement="top" title="Please Enter last 3 digits"></i></label>
																<input type="number" className="form-control" required=""/>
															</div>
														</div>
													</div>
												</div>
												<div className="tab-pane " id="tab6">
													<h6 className="font-weight-semibold">Paypal is easiest way to pay online</h6>
													<p><Link to="javascript:void(0);" className="btn btn-primary"><i className="fa fa-paypal"></i> Log in my Paypal</Link></p>
													<p className="mb-0"><strong>Note:</strong> Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. </p>
												</div>
												<div className="tab-pane " id="tab7">
													<div className="control-group form-group">
														<div className="form-group">
															<label className="form-label text-dark">All Banks</label>
															<select className="form-control select2  form-select required category">
																<option value="0">Select Bank</option>
																<option value="1">Credit Agricole Group</option>
																<option value="2">Bank of America</option>
																<option value="3">Mitsubishi UFJ Financial Group</option>
																<option value="4">BNP Paribas</option>
																<option value="5">JPMorgan Chase & Co.</option>
																<option value="6">HSBC Holdings</option>
																<option value="7">Bank of China</option>
																<option value="8">Agricultural Bank of China</option>
																<option value="9">ChinaFlats Bank Corp.</option>
																<option value="10">Industrial & Commercial Bank of China, or ICBC</option>
															</select>
														</div>
													</div>
													<p><Link to="javascript:void(0);" className="btn btn-primary">Log in Bank</Link></p>
												</div>
												<div className="tab-pane " id="tab8">
													<div className="form-group">
														<label className="form-label">Gift Voucher</label>
														<div className="input-group">
															<input type="text" className="form-control" placeholder="Enter Your Gv Number"/>
																<button className="btn btn-info" type="button">
																Apply</button>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div className="form-group row clearfix">
										<div className="col-lg-12">
											<div className="checkbox checkbox-info">
												<label className="custom-control mt-4 custom-checkbox">
													<input type="checkbox" className="custom-control-input" />
													<span className="custom-control-label text-dark ps-2">I agree with the Terms and Conditions.</span>
												</label>
											</div>
										</div>
										<ul className=" mb-b-4 ">
											<li className="float-end"><Link to="javascript:void(0);" className="btn btn-primary  mb-0 me-2">Continue</Link></li>
										</ul>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
		{/* <!--/Add posts-section--> */}

		{/* <!-- Newsletter--> */}
		<section className="sptb bg-white border-top">
			<div className="container">
				<div className="row">
					<div className="col-lg-7 col-xl-6 col-md-12">
						<div className="sub-newsletter">
							<h3 className="mb-2"><i className="fa fa-paper-plane-o me-2"></i> Subscribe To Our Newsletter</h3>
							<p className="mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor</p>
						</div>
					</div>
					<div className="col-lg-5 col-xl-6 col-md-12">
						<div className="input-group sub-input mt-1">
							<input type="text" className="form-control input-lg " placeholder="Enter your Email"/>
								<button type="button" className="btn btn-primary btn-lg br-tr-3  br-br-3">
									Subscribe
								</button>
						</div>
					</div>
				</div>
			</div>
		</section>
		{/* <!--/Newsletter--> */}

        <Footer/>
    </Fragment>
  )
}

export default Adpost