import { Fragment } from 'react'

const Statistics = () => {
    return (
        <Fragment>

            {/* <!--Statistics-->  */}
            <section>
                <div className="about-1 cover-image sptb bg-background2" data-bs-image-src="assets/images/banners/banner5.jpg">
                    <div className="content-text mb-0 text-white info">
                        <div className="container">
                            <div className="row text-center">
                                <div className="col-lg-3 col-md-6">
                                    <div className="counter-status md-mb-0">
                                        <div className="counter-icon">
                                            <i className="icon icon-people"></i>
                                        </div>
                                        <h5>Total Agents</h5>
                                        <h2 className="counter mb-0 font-weight-bold">893</h2>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6">
                                    <div className="counter-status status-1 md-mb-0">
                                        <div className="counter-icon text-warning">
                                            <i className="icon icon-rocket"></i>
                                        </div>
                                        <h5>Total Sales</h5>
                                        <h2 className="counter mb-0 font-weight-bold">1765</h2>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6">
                                    <div className="counter-status status md-mb-0">
                                        <div className="counter-icon text-primary">
                                            <i className="icon icon-docs"></i>
                                        </div>
                                        <h5>Total Projects</h5>
                                        <h2 className="counter mb-0 font-weight-bold">846</h2>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6">
                                    <div className="counter-status status">
                                        <div className="counter-icon text-success">
                                            <i className="icon icon-emotsmile"></i>
                                        </div>
                                        <h5>Happy Customers</h5>
                                        <h2 className="counter font-weight-bold mb-0">7253</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!--/Statistics--> */}
    </Fragment>
  )
}

export default Statistics