import { Fragment } from 'react'

const Download = () => {
  return (
    <Fragment>
        {/* <!--Download --> */}
			<section className="sptb">
				<div className="container">
					<div className="section-title center-block text-center">
						<h2>Download</h2>
						<p>Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua</p>
					</div>
					<div className="row">
						<div className="col-md-12">
							<div className="text-center text-wrap">
								<div className="btn-list">
									<a href="javascript:void(0);" className="btn btn-primary btn-lg mb-sm-0"><i className="fa fa-apple fa-1x me-2"></i> App Shrefre</a>
									<a href="javascript:void(0);" className="btn btn-secondary btn-lg mb-sm-0"><i className="fa fa-android fa-1x me-2"></i> Google Play</a>
									<a href="javascript:void(0);" className="btn btn-info btn-lg mb-sm-0"><i className="fa fa-windows fa-1x me-2"></i> Windows</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* <!--Download --> */}
    </Fragment>
  )
}

export default Download