import React from 'react'

const Register = () => {
  return (
    <>
     <form action=''>
              {/*Sliders Section*/}
            <section>
                <div
                    className="bannerimg cover-image bg-background3"
                    data-bs-image-src="assets/images/banners/banner2.jpg"
                >
                    <div className="header-text mb-0">
                        <div className="container">
                            <div className="text-center text-white">
                                <h1 className="">Login</h1>
                                <ol className="breadcrumb text-center">
                                    <li className="breadcrumb-item">
                                        <a href="/">Home</a>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <a href="javascript:void(0);">Pages</a>
                                    </li>
                                    <li
                                        className="breadcrumb-item active text-white"
                                        aria-current="page"
                                    >
                                        Login
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/*/Sliders Section*/}
            {/*Login-Section*/}
            <section className="sptb">
                <div className="container customerpage">
                    <div className="row">
                        <div className="col-lg-5 col-xl-4 col-md-6 d-block mx-auto">
                            <div className="row">
                                <div className="col-xl-12 col-md-12 col-md-12 register-right">
                                    <ul
                                        className="nav nav-tabs nav-justified mb-5 p-2 border"
                                        id="myTab"
                                        role="tablist"
                                    >
                                        <li className="nav-item">
                                            <a
                                                className="nav-link active m-1 border "
                                                id="home-tab"
                                                data-bs-toggle="tab"
                                                href="#home"
                                                role="tab"
                                                aria-controls="home"
                                                aria-selected="true"
                                            >
                                                Login
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a
                                                className="nav-link m-1 border "
                                                id="profile-tab"
                                                data-bs-toggle="tab"
                                                href="#profile"
                                                role="tab"
                                                aria-controls="profile"
                                                aria-selected="false"
                                            >
                                                Register
                                            </a>
                                        </li>
                                    </ul>
                                    <div className="tab-content" id="myTabContent">
                                        <div
                                            className="tab-pane fade show active"
                                            id="home"
                                            role="tabpanel"
                                            aria-labelledby="home-tab"
                                        >
                                            <div className="single-page  w-100  p-0">
                                                <div className="wrapper wrapper2">
                                                    <form id="login" className="card-body" tabIndex={500}>
                                                        <h3 className="pb-2">Login</h3>
                                                        <div className="mail">
                                                            <input type="email" name="mail" />
                                                            <label htmlFor='email'>Mail or Username</label>
                                                        </div>
                                                        <div className="passwd">
                                                            <input type="password" name="password" />
                                                            <label htmlFor='password'>Password</label>
                                                        </div>
                                                        <div className="submit">
                                                            <a
                                                                className="btn btn-primary btn-block"
                                                                href="javascript:void(0);"
                                                            >
                                                                Login
                                                            </a>
                                                        </div>
                                                        <p className="mb-2">
                                                            <a href="forgot.html">Forgot Password</a>
                                                        </p>
                                                        <p className="text-dark mb-0">
                                                            Don't have account?
                                                            <a href="javascript:void(0);" className="text-primary mx-1">
                                                                Sign UP
                                                            </a>
                                                        </p>
                                                    </form>
                                                    <hr className="divider" />
                                                    <div className="p-3 pb-0">
                                                        <div className="row">
                                                            <div className="col-6 mb-2">
                                                                <a
                                                                    href="https://www.facebook.com/"
                                                                    className="btn btn-light btn-block text-start"
                                                                >
                                                                    <img
                                                                        src="assets/images/svg/facebook.svg"
                                                                        alt=""
                                                                        className="w-4 h-4 me-2"
                                                                    />
                                                                    <span className="font-weight-bold fs-15">
                                                                        Facebook
                                                                    </span>
                                                                </a>
                                                            </div>
                                                            <div className="col-6  mb-2">
                                                                <a
                                                                    href="https://www.google.com/gmail/"
                                                                    className="btn btn-light btn-block text-start"
                                                                >
                                                                    <img
                                                                        src="assets/images/svg/google.svg"
                                                                        alt=""
                                                                        className="w-4 h-4 me-2"
                                                                    />
                                                                    <span className="font-weight-bold fs-15">
                                                                        Google
                                                                    </span>
                                                                </a>
                                                            </div>
                                                            <div className="col-6  mb-2">
                                                                <a
                                                                    href="https://twitter.com/"
                                                                    className="btn btn-light btn-block text-start"
                                                                >
                                                                    <img
                                                                        src="assets/images/svg/linkedin.svg"
                                                                        alt=""
                                                                        className="w-4 h-4 me-2"
                                                                    />
                                                                    <span className="font-weight-bold fs-15">
                                                                        LinkedIn
                                                                    </span>
                                                                </a>
                                                            </div>
                                                            <div className="col-6  mb-2">
                                                                <a
                                                                    href="https://twitter.com/"
                                                                    className="btn btn-light btn-block text-start"
                                                                >
                                                                    <img
                                                                        src="assets/images/svg/twitter.svg"
                                                                        alt=""
                                                                        className="w-4 h-4 me-2"
                                                                    />
                                                                    <span className="font-weight-bold fs-15">
                                                                        Twitter
                                                                    </span>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className="tab-pane fade show"
                                            id="profile"
                                            role="tabpanel"
                                            aria-labelledby="profile-tab"
                                        >
                                            <div className="single-page w-100  p-0">
                                                <div className="wrapper wrapper2">
                                                    <form id="Register" className="card-body" tabIndex={500}>
                                                        <h3 className="pb-1">Register</h3>
                                                        <div className="name">
                                                            <input type="text" name="name" />
                                                            <label htmlFor='name'>Name</label>
                                                        </div>
                                                        <div className="mail">
                                                            <input type="email" name="mail" />
                                                            <label htmlFor='email'>Mail or Username</label>
                                                        </div>
                                                        <div className="passwd">
                                                            <input type="password" name="password" />
                                                            <label htmlFor=''>Password</label>
                                                        </div>
                                                        <div className="submit">
                                                            <a
                                                                className="btn btn-primary btn-block"
                                                                href="javascript:void(0);"
                                                            >
                                                                Register
                                                            </a>
                                                        </div>
                                                        <p className="text-dark mb-0">
                                                            Already have an account?
                                                            <a href="javascript:void(0);" className="text-primary mx-1">
                                                                Sign In
                                                            </a>
                                                        </p>
                                                    </form>
                                                    <hr className="divider" />
                                                    <div className="p-3 pb-0">
                                                        <div className="row">
                                                            <div className="col-6 mb-2">
                                                                <a
                                                                    href="https://www.facebook.com/"
                                                                    className="btn btn-light btn-block text-start"
                                                                >
                                                                    <img
                                                                        src="assets/images/svg/facebook.svg"
                                                                        alt=""
                                                                        className="w-4 h-4 me-2"
                                                                    />
                                                                    <span className="font-weight-bold fs-15">
                                                                        Facebook
                                                                    </span>
                                                                </a>
                                                            </div>
                                                            <div className="col-6  mb-2">
                                                                <a
                                                                    href="https://www.google.com/gmail/"
                                                                    className="btn btn-light btn-block text-start"
                                                                >
                                                                    <img
                                                                        src="assets/images/svg/google.svg"
                                                                        alt=""
                                                                        className="w-4 h-4 me-2"
                                                                    />
                                                                    <span className="font-weight-bold fs-15">
                                                                        Google
                                                                    </span>
                                                                </a>
                                                            </div>
                                                            <div className="col-6  mb-2">
                                                                <a
                                                                    href="https://twitter.com/"
                                                                    className="btn btn-light btn-block text-start"
                                                                >
                                                                    <img
                                                                        src="assets/images/svg/linkedin.svg"
                                                                        alt=""
                                                                        className="w-4 h-4 me-2"
                                                                    />
                                                                    <span className="font-weight-bold fs-15">
                                                                        LinkedIn
                                                                    </span>
                                                                </a>
                                                            </div>
                                                            <div className="col-6  mb-2">
                                                                <a
                                                                    href="https://twitter.com/"
                                                                    className="btn btn-light btn-block text-start"
                                                                >
                                                                    <img
                                                                        src="assets/images/svg/twitter.svg"
                                                                        alt=""
                                                                        className="w-4 h-4 me-2"
                                                                    />
                                                                    <span className="font-weight-bold fs-15">
                                                                        Twitter
                                                                    </span>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/*/Login-Section*/}
            {/* Newsletter*/}
            {/* <section className="sptb bg-white border-top">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7 col-xl-6 col-md-12">
                            <div className="sub-newsletter">
                                <h3 className="mb-2">
                                    <i className="fa fa-paper-plane-o me-2" /> Subscribe To Our
                                    Newsletter
                                </h3>
                                <p className="mb-0">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                    eiusmod tempor
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-5 col-xl-6 col-md-12">
                            <div className="input-group sub-input mt-1">
                                <input
                                    type="text"
                                    className="form-control input-lg "
                                    placeholder="Enter your Email"
                                />
                                <button
                                    type="button"
                                    className="btn btn-primary btn-lg br-tr-3  br-br-3"
                                >
                                    Subscribe
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
            {/*/Newsletter*/}

            {/* <script>
                document.addEventListener('DOMContentLoaded', function() {
                    // Your code here
                    // It will execute after the DOM has fully loaded
                });
            </script> */}
          </form>





    
        




       

   </>
  )
}

export default Register